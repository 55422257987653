import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {CreateFinancialRequest} from 'kfp';
import {cloneDeep} from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class MortgageParametersService {
    constructor(private readonly translateService: TranslateService) {

    }

    public parameters: BehaviorSubject<any> = new BehaviorSubject<any>(
        {
            items:
                [
                    {
                        name: 'Varianta 1',
                        description: 'Lorem ipsum',
                        data: {
                            mortgage: {
                                futureValue: 0,
                                initialPayment: 3000000,
                                interestFrequency: 'Monthly',
                                monthlyPaymentFlaged: 5000,
                                rate: 0.049,
                                yearlyDuration: 20,
                                flag: 'monthlyPayment',
                                active: true
                            },
                            investment: {
                                futureValueFlaged: 3000000,
                                initialPayment: 100000,
                                interestFrequency: 'Monthly',
                                monthlyPayment: 5000,
                                rate: 0.06,
                                yearlyDuration: 20,
                                flag: 'futureValue',
                                active: true
                            },
                            comments: []
                        }
                    }
                ]
        }
    );
    sharedParams$: Observable<any> = this.parameters.asObservable();

    private _selectedTab: any = 0;
    private selectedTabSubject: BehaviorSubject<any> = new BehaviorSubject(this._selectedTab);
    selectedTab$: Observable<any> = this.selectedTabSubject.asObservable();

    private _comparingTabs: number[] = [0];
    private comparingTabsSubject: BehaviorSubject<number[]> = new BehaviorSubject(this._comparingTabs);
    comparingTabs$: Observable<number[]> = this.comparingTabsSubject.asObservable();

    private _invalidTab: any = null;
    private invalidTabSubject: BehaviorSubject<any> = new BehaviorSubject(this._invalidTab);
    invalidTab$: Observable<any> = this.invalidTabSubject.asObservable();

    private _result: any = [{mortgageResult: 0, investResult: 0}];
    private resultSubject: BehaviorSubject<any> = new BehaviorSubject(this._result);
    result$: Observable<any> = this.resultSubject.asObservable();

    private _chartData: any = null;
    private chartDataSubject: BehaviorSubject<any> = new BehaviorSubject(this._chartData);
    chartData$: Observable<any> = this.chartDataSubject.asObservable();


    getResult(): any {
        return this._result;
    }

    setResults(value: any) {
        this._result = value;
        this.resultSubject.next(this.getResult());
    }

    getChartData(): any {
        return this._chartData;
    }

    setChartData(value: any) {
        this._chartData = value;
        this.chartDataSubject.next(this.getChartData());
    }

    getInvalidTab(): any {
        return this._invalidTab;
    }

    setInvalidTab(value: any) {
        this._invalidTab = value;
        this.invalidTabSubject.next(value);
    }

    getSelectedTab(): any {
        return this._selectedTab;
    }

    setSelectedTab(value: any) {
        this._selectedTab = value;
        this.selectedTabSubject.next(value);
    }

    getComparingTabs(): number[] {
        return this._comparingTabs;
    }

    setComparingTabs(value: number[]) {
        this._comparingTabs = value;
        this.comparingTabsSubject.next(value);
    }

    addToComparingTabs(numberToAdd: number) {
        this._comparingTabs.push(numberToAdd);
        this.comparingTabsSubject.next(this._comparingTabs);
    }

    removeFromComparingTabs(numberToRemove: number) {
        const index = this._comparingTabs.indexOf(numberToRemove);
        if (index !== -1) {
            this._comparingTabs.splice(index, 1);
            this.comparingTabsSubject.next(this._comparingTabs);
        }
    }

    getParameters(): any {
        return this.parameters.value;
    }

    setParameters(data: CreateFinancialRequest): void {
        // Iterate through the array and check each item for validity
        for (const [index, item] of data.items.entries()) {
            if (item && item.data) {
                // Check mortgage
                if (item.data.mortgage) {
                    for (const [key, value] of Object.entries(item.data.mortgage)) {
                        if ((typeof value === 'number' && isNaN(value)) || (value === undefined && !key.endsWith('Flaged'))) {
                            // If any invalid parameter found, return without applying new values
                            this.setInvalidTab(index);
                            return;
                        }
                    }
                }
                // Check investment
                if (item.data.investment) {
                    for (const [key, value] of Object.entries(item.data.investment)) {
                        if ((typeof value === 'number' && isNaN(value)) || (value === undefined && !key.endsWith('Flaged'))) {
                            // If any invalid parameter found, return without applying new values
                            this.setInvalidTab(index);
                            return;
                        }
                    }
                }
            }
        }
        this.setInvalidTab(null);
        // Apply new values if all parameters are valid
        this.parameters.next(data);
    }


    resetParameters(): void {

        this._selectedTab = 0;
        this._invalidTab = null;
        this._result = [{mortgageResult: 5, investResult: 6}];

        this.selectedTabSubject.next(this._selectedTab);
        this.invalidTabSubject.next(this._invalidTab );
        this.resultSubject.next(this._result);

        const defaultParams: any = {
            items:
                [
                    {
                        name: 'Varianta 1',
                        description: 'Lorem ipsum',
                        data: {
                            mortgage: {
                                futureValue: 0,
                                initialPayment: 3000000,
                                interestFrequency: 'Monthly',
                                monthlyPaymentFlaged: 5000,
                                rate: 0.049,
                                yearlyDuration: 20,
                                flag: 'monthlyPayment',
                                active: true
                            },
                            investment: {
                                futureValueFlaged: 3000000,
                                initialPayment: 100000,
                                interestFrequency: 'Monthly',
                                monthlyPayment: 5000,
                                rate: 0.06,
                                yearlyDuration: 20,
                                flag: 'futureValue',
                                active: true
                            },
                            comments: []
                        }
                    }
                ]
        }
        this.parameters.next(defaultParams);
    }
}
