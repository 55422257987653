import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
    AuthorizationPasswordService,
    ResetUserPasswordRequest, StripeService,
    UpdateUserRequest,
    UserService
} from 'kfp';
import {AuthService} from '../auth/auth.service';


@Injectable({
    providedIn: 'root'
})
export class ProfileService {


    constructor(private _httpClient: HttpClient,
                private _userService: UserService,
                private _authService: AuthService,
                private _stripeService: StripeService,
                private _authPassService: AuthorizationPasswordService) {
    }

    public setNewPassword(
        req: ResetUserPasswordRequest
    ): Observable<any> {
        return this._authPassService.resetUserPassword(req);
    }

    updateAuthUser(id: string, user: UpdateUserRequest): any {
        return this._userService.updateUserById(id, user);
    }

    getAuthUser(): Observable<any> {
        return this._authService.profile();
    }



    get currency(): string {
        return 'Kč';
    }


    public getUpdateUserBranding(): any {
        return null;
    }

    public clearProfile(): void {
        this._authService.clearLoggedUserCache();
    }

    public activeProfile() {
        return this._authService.profile();
    }

    public refreshProfile() {
        return this._authService.refreshProfile();
    }

    public sendUpdateLogoToProject(message: any) {
        //this.userLogo.next(message);
    }

    putUserColor(id: string, color: string): Observable<any> {
        return this._userService.updateUserColor(id, {color: color});
    }

    deleteLogo(id: string): Observable<any> {
        return this._userService.deleteAvatarFromUser(id);
    }

    putUserLogo(id: string, file: Blob): any {
        return this._userService.updateUserLogo(id, file);
    }

    getAuthUserSubscription(): any {
        return this._stripeService.checkUserSubscription();
    }

    getStripeCoupon(coupon: string | undefined): any {
        return undefined;
    }

    getTrialUserInformation(): any {
        return null;
    }


}
