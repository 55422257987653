import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree,
} from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AuthService } from '../auth.service';
import * as CryptoJS from 'crypto-js';
import { KfpStripeService } from '../../utils/stripe/kfpstripe.service';

@Injectable({
    providedIn: 'root',
})
export class ImpersonateGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(
        private _authService: AuthService,
        private _router: Router,
        private kfpStripeService: KfpStripeService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const impersonateParam = route.queryParams['impersonate'];
        if (impersonateParam) {
            this._authService.accessToken = this.decryptEncryptedToken(decodeURIComponent(impersonateParam));
            this._authService.clearLoggedUserCache();
            this._authService.setActualCalcDate();

            this.kfpStripeService.loadStripe();
            this._authService.getFlags().subscribe();

            this._router.navigate(['/signed-in-redirect']);
            return false;
        }

        return true;
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this._check();
    }

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        return this._check();
    }

    private _check(): Observable<boolean> {
        return this._authService.check().pipe(switchMap(() => of(true)));
    }

    private decryptEncryptedToken(encryptedToken: string): string {
        try {
            const decryptedBytes = CryptoJS.AES.decrypt(encryptedToken, 'accessTokenImpersonateEncryptKey');
            return decryptedBytes.toString(CryptoJS.enc.Utf8);
        } catch (error) {
            console.error('Error during decryption:', error);
            return '';
        }
    }
}
