import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntilDestroy} from '@ngneat/until-destroy';


@UntilDestroy()
@Component({
    selector: 'finmath-pdf-dialog',
    templateUrl: './finmath-pdf-dialog.component.html',
    styleUrls: ['./finmath-pdf-dialog.component.scss'],
})
export class FinmathPdfDialogComponent implements OnInit {

    credit = true;
    investment = true;
    rent = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) public dataRef: any,
        private dialogRef: MatDialogRef<any>,
    ) {
    }

    ngOnInit(): void {
        this.dialogRef.updateSize('550px', '360px');
    }

    public submit(): boolean {

        this.dialogRef.close({credit: this.credit, investment: this.investment, rent: this.rent});
        return false;
    }

}
