import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {combineLatest, map, Observable, switchMap} from 'rxjs';
import {
    ApexAxisChartSeries,
    ApexChart,
    ApexDataLabels,
    ApexFill,
    ApexLegend,
    ApexMarkers,
    ApexPlotOptions,
    ApexResponsive,
    ApexStroke,
    ApexTitleSubtitle,
    ApexTooltip,
    ApexXAxis,
    ApexYAxis
} from 'ng-apexcharts';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {CreditChart} from '../charts/credit-chart';
import {InvestmentChart} from '../charts/investment-chart';
import {RentChart} from '../charts/rent-chart';
import {FinMathKfpService} from './finmath.service';
import {CreateFinMathRequest} from 'kfp';

export type FinMathChartType = 'credit' | 'investment' | 'rent';
export type FinMathChartOptions = {
    params?: any;
};


export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xaxis?: ApexXAxis;
    stroke?: ApexStroke;
    dataLabels?: ApexDataLabels;
    yaxis?: ApexYAxis;
    title?: ApexTitleSubtitle;
    labels?: string[];
    legend?: ApexLegend;
    subtitle?: ApexTitleSubtitle;
    plotOptions?: ApexPlotOptions;
    colors: string[];
    tooltip: ApexTooltip;
    fill?: ApexFill;
    markers?: ApexMarkers;
    responsive?: ApexResponsive;
};

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class FinMathChartService {
    public locale = 'cs';

    constructor(
        public translateService: TranslateService,
        private _httpClient: HttpClient,
        private _finMathService: FinMathKfpService,
        private readonly _creditChart: CreditChart,
        private readonly _investmentChart: InvestmentChart,
        private readonly _rentChart: RentChart,
    ) {
    }

    public creditChart(parameters: CreateFinMathRequest, updateTotal = true): Observable<ChartOptions> {
        return this._finMathService.dataCredit(parameters, updateTotal).pipe(
            untilDestroyed(this),
            switchMap((res) => {
                this._creditChart.updateSeries(res);
                return this._creditChart.getChartData();
            }),
        );
    }

    public investmentChart(parameters: CreateFinMathRequest, updateTotal = true): Observable<ChartOptions> {
        return this._finMathService.dataInvestment(parameters, updateTotal).pipe(
            untilDestroyed(this),
            switchMap((res) => {
                this._investmentChart.updateSeries(res);
                return this._investmentChart.getChartData();
            })
        );
    }


    public rentChart(parameters: CreateFinMathRequest, updateTotal = true): Observable<ChartOptions> {
        return this._finMathService.dataRent(parameters, updateTotal).pipe(
            untilDestroyed(this),
            switchMap((res) => {
                this._rentChart.updateSeries(res);
                return this._rentChart.getChartData();
            })
        );
    }

    public chartByType(type: FinMathChartType, parameters: CreateFinMathRequest, options?: FinMathChartOptions) {
        switch (type) {
            case 'credit':
                return this.creditChart(parameters);
            case 'investment':
                return this.investmentChart(parameters);
            case 'rent':
                return this.rentChart(parameters);
        }
    }

    prepareDataForPdfExport(present: any): Observable<{
        credit: ChartOptions;
        investment: ChartOptions;
        rent: ChartOptions;
    }> {
        return combineLatest([
            this.creditChart(present?.credit, false),
            this.investmentChart(present?.investment, false),
            this.rentChart(present?.rent, false),
        ]).pipe(
            map(([credit, investment, rent]) => ({
                credit: credit,
                investment: investment,
                rent: rent,
            }))
        );
    }


}


