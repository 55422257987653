import { Injectable } from '@angular/core';
import moment from 'moment';

export const calculationDefaults: any = {
  /* Finanční matematika */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  fin_math: {
    parameters : {
      futureValue: 0,
      initialPayment: 3000000,
      interestFrequency: 'Annually',
      monthlyPayment: 5000,
      rate: 0.04,
      yearlyDuration: 25
    },
    mode: 'credit',
    flagField: 'initialPayment',
    presets: {
      credit : {
        futureValue: 0,                 // Zůstatek
        initialPayment: 3000000,        // Výše úvěru
        interestFrequency: 'Monthly',
        monthlyPayment: 10118,
        rate: 0.035,
        yearlyDuration: 20,
        flag: 'monthlyPayment'
      },
      investment: {
        futureValue: null,
        initialPayment: 100000,
        interestFrequency: 'Monthly',
        monthlyPayment: 5000,
        rate: 0.06,
        yearlyDuration: 20,
        flag: 'futureValue'
      },
      rent: {
        futureValue: 0,
        initialPayment: 1000000,
        interestFrequency: 'Monthly',
        monthlyPayment: 5000,
        rate: 0.04,
        yearlyDuration: 20,
        flag: 'yearlyDuration'
      }
    }
  },
  /* Pravděpodobnost výnosů */
  probability: {
    parameters: {
      dateFrom: moment(localStorage.getItem('lastDate'), 'YYYY-MM-DD').subtract(20, 'years'),
      dateTill: moment(localStorage.getItem('lastDate'), 'YYYY-MM-DD'),
      startYear: moment(localStorage.getItem('lastDate'), 'YYYY-MM-DD').subtract(20, 'years').year(),
      startMonth: moment(localStorage.getItem('lastDate'), 'YYYY-MM-DD').subtract(20, 'years').month()+1,
      duration: 20,
      initialInvestment: 100000,
      monthlyInvestment: 3000,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      basis_notice: undefined,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      history_notice: undefined,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      final_notice: undefined,
      bonds: 0,
      shares: 100,
      finMarket: 0,
      yieldType: 'Nominal',
      breakType: undefined,
      bondsAllocation: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      finMarketAllocation: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      sharesAllocation: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      profitFees: {
        bondsFee: 0,
        sharesFee: 0,
        finMarketFee: 0
      },
      allocationFees: {
        bondsFee: 0,
        sharesFee: 0,
        finMarketFee: 0
      },
      investmentFee: 0,
    },
    mode: 'dynamic',
    modeRatios: {
      dynamic: {
        shares: 100, // Akcie
        bonds: 0,    // Dluhopisy
        finMarket: 0  // Finanční trh
      },
      balanced: {
        shares: 60,
        bonds: 40,
        finMarket: 0
      },
      conservative: {
        shares: 50,
        bonds: 50,
        finMarket: 0
      }
    }
  },
  history: {
    parameters:
      {
        dateFrom: moment(localStorage.getItem('lastDate'), 'YYYY-MM-DD').subtract(20, 'years'),
        dateTill: moment(localStorage.getItem('lastDate'), 'YYYY-MM-DD'),
        startYear: moment(localStorage.getItem('lastDate'), 'YYYY-MM-DD').subtract(20, 'years').year(),
        startMonth: moment(localStorage.getItem('lastDate'), 'YYYY-MM-DD').subtract(20, 'years').month()+1,
        duration: 20,
        initialInvestment: 100000,
        monthlyInvestment: 3000,
        investmentFee: 0,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        market_notice: undefined,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        evaluation_notice: undefined,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        evolution_notice: undefined,
        bonds: 0,
        shares: 100,
        finMarket: 0,
        yieldType: 'Nominal',
        breakType: undefined,
        bondsAllocation: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        finMarketAllocation: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        sharesAllocation: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        profitFees: {
          bondsFee: 0,
          finMarketFee: 0,
          sharesFee: 0
        },
        allocationFees: {
          bondsFee: 0,
          sharesFe: 0,
          finMarketFee: 0
        },
        showFees: false
      },
    modeRatios: {
      dynamic: {
        shares: 100, // Akcie
        bonds: 0,    // Dluhopisy
        finMarket: 0  // Finanční trh
      },
      balanced: {
        shares: 60,
        bonds: 40,
        finMarket: 0
      },
      conservative: {
        shares: 50,
        bonds: 50,
        finMarket: 0
      }
    },
    breakPresets: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      30: {
        conservative: [[30, 35, 35], [30, 50, 20], [30, 50, 20], [30, 50, 20], [30, 50, 20], [30, 50, 20], [30, 50, 20], [30, 50, 20], [30, 50, 20], [30, 50, 20]],
        balanced:  [[44, 28, 28], [50, 25, 25], [54, 23, 23],  [60, 20, 20],  [60, 40, 0],  [60, 40, 0],  [60, 40, 0],  [60, 40, 0], [60, 40, 0],  [60, 40, 0]],
        dynamic:  [[60, 20, 20], [64, 18, 18], [68, 16, 16], [72, 14, 14],  [76, 12, 12],  [80, 10, 10],  [84, 8, 8],  [88, 6, 6], [92, 4, 4],  [96, 2, 2]],
      },
      zero: {
        conservative: [[0, 0, 100], [10, 40, 50], [20, 60, 20], [30, 50, 20], [30, 50, 20], [30, 50, 20], [30, 50, 20], [30, 50, 20], [30, 50, 20], [30, 50, 20]],
        balanced: [[0, 0, 100], [10, 40, 50], [20, 60, 20], [30, 60, 10], [40, 60, 0], [50, 50, 0], [60, 40, 0], [60, 40, 0], [60, 40, 0], [60, 40, 0]],
        dynamic: [[0, 0, 100], [10, 40, 50], [20, 60, 20], [30, 60, 10], [40, 60, 0], [50, 50, 0], [60, 40, 0], [70, 30, 0], [80, 20, 0], [90, 10, 0]]
      },
      custom: undefined
    },
    mode: 'dynamic'

  }
};

@Injectable({
  providedIn: 'root'
})
export class CalculationsSettingsService {
  public getCalcRoute(type: string): string {
    switch (type) {
        case 'history':
            return '/historical';
        case 'probability':
            return '/probability';
        case 'fin_math':
            return '/fin_math';
        case 'profit':
            return '/profit';
        case 'mortgage':
            return '/mortgage';
        default:
            return '/historical';
    }
}
public getISODate(date: string): string {
  return moment(date, 'DD.MM.YYYY HH:mm').toISOString();
}
}
