<div class="h-full shadow-2xl flex flex-col justify-between">
    <div>
        <!-- Header -->
        <div class="">
            <ng-content select="[fuseVerticalNavigationHeader]"></ng-content>
        </div>

        <!-- Content -->
        <div class="" fuseScrollbar [fuseScrollbarOptions]="{ wheelPropagation: inner, suppressScrollX: true }" #navigationContent>
            <!-- Content header -->
            <div class="">
                <ng-content select="[fuseVerticalNavigationContentHeader]"></ng-content>
            </div>

            <!-- Items -->
            <ng-container *ngFor="let item of navigation; trackBy: trackByFn">
                <!-- Skip the hidden items -->
                <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">
                    <!-- Aside -->
                    <ng-container *ngIf="item.type === 'aside'">
                        <fuse-vertical-navigation-aside-item
                            [item]="item"
                            [name]="name"
                            [activeItemId]="activeAsideItemId"
                            [autoCollapse]="autoCollapse"
                            [skipChildren]="true"
                            (click)="toggleAside(item)"
                        ></fuse-vertical-navigation-aside-item>
                    </ng-container>

                    <!-- Basic -->
                    <ng-container *ngIf="item.type === 'basic'">
                        <fuse-vertical-navigation-basic-item [item]="item" [name]="name"></fuse-vertical-navigation-basic-item>
                    </ng-container>

                    <!-- Collapsable -->
                    <ng-container *ngIf="item.type === 'collapsable'">
                        <fuse-vertical-navigation-collapsable-item
                            [item]="item"
                            [name]="name"
                            [autoCollapse]="autoCollapse"
                        ></fuse-vertical-navigation-collapsable-item>
                    </ng-container>

                    <!-- Divider -->
                    <ng-container *ngIf="item.type === 'divider'">
                        <fuse-vertical-navigation-divider-item [item]="item" [name]="name"></fuse-vertical-navigation-divider-item>
                    </ng-container>

                    <!-- Group -->
                    <ng-container *ngIf="item.type === 'group'">
                        <fuse-vertical-navigation-group-item
                            [item]="item"
                            [name]="name"
                            [autoCollapse]="autoCollapse"
                        ></fuse-vertical-navigation-group-item>
                    </ng-container>

                    <!-- Spacer -->
                    <ng-container *ngIf="item.type === 'spacer'">
                        <fuse-vertical-navigation-spacer-item [item]="item" [name]="name"></fuse-vertical-navigation-spacer-item>
                    </ng-container>
                </ng-container>
            </ng-container>

            <!-- Content footer -->
            <div class="fuse-vertical-navigation-content-footer">
                <ng-content select="[fuseVerticalNavigationContentFooter]"></ng-content>
            </div>
        </div>

        <!-- Footer -->
        <div class="fuse-vertical-navigation-footer">
            <ng-content select="[fuseVerticalNavigationFooter]"></ng-content>
            <div class="w-11/12 flex flex-col items-center text-base justify-center border px-4 py-6 mt-8 mx-4 mt-16">
                <div class="text-primary mb-2">
                    {{ 'section.vertical.nav.questions' | translate }}
                </div>
                <div>
                    {{ 'section.vertical.nav.contact_us' | translate }}
                    <a class="text-blue-600" href="mailto:klient@kfp.cz">klient@kfp.cz</a>
                </div>
            </div>
        </div>
    </div>

    <div class="w-full h-full flex justify-center items-end grow mb-8">
        <button mat-stroked-button class="scale-125 rounded-md mt-10 mb-2" (click)="signOut()">
            {{ 'section.vertical.nav.logout' | translate }}
        </button>
    </div>
</div>

<!-- Aside -->
<ng-container *ngIf="activeAsideItemId">
    <div
        class=""
        fuseScrollbar
        [fuseScrollbarOptions]="{ wheelPropagation: false, suppressScrollX: true }"
        [@fadeInLeft]="position === 'left'"
        [@fadeInRight]="position === 'right'"
        [@fadeOutLeft]="position === 'left'"
        [@fadeOutRight]="position === 'right'"
    >
        <!-- Items -->
        <ng-container *ngFor="let item of navigation; trackBy: trackByFn">
            <!-- Skip the hidden items -->
            <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">
                <!-- Aside -->
                <ng-container *ngIf="item.type === 'aside' && item.id === activeAsideItemId">
                    <fuse-vertical-navigation-aside-item
                        [item]="item"
                        [name]="name"
                        [autoCollapse]="autoCollapse"
                    ></fuse-vertical-navigation-aside-item>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</ng-container>
