import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CalculationService } from '../../api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { calculationDefaults, CalculationsSettingsService } from '../calculations-settings.service';
import { Router } from '@angular/router';
import { AppService } from '../../app-service/kfp.service';
import { ProbabilityParametersService } from '../probability/domain/services/probability-parameters.service';
import { ProbabilityChartService } from '../probability/domain/services/probability-chart.service';
import {ProfileService} from '../../profile/profile.service';

@UntilDestroy()
@Component({
    selector: 'calculations-dialog',
    templateUrl: './calculations-dialog.component.html',
    styleUrls: ['./calculations-dialog.component.scss'],
})
export class CalculationsDialogComponent implements OnInit {
    public form: FormGroup;
    public data: any = {};
    public calculations: any = {};
    public isCopy = false;
    public colors = { primary: 'darkGreen', secondary: 'brown' };

    constructor(
        @Inject(MAT_DIALOG_DATA) public dataRef: any,
        private dialogRef: MatDialogRef<any>,
        private readonly formBuilder: FormBuilder,
        private readonly calcService: CalculationService,
        private readonly router: Router,
        private profileService: ProfileService,
        private readonly appService: AppService,
        private readonly calcSettingsService: CalculationsSettingsService,
        private _probabilityParametersService: ProbabilityParametersService,
        private _probabilityChartService: ProbabilityChartService
    ) {
        this.data = dataRef.calculation;
        if (this.dataRef.copy) {
            this.isCopy = true;
        }
        this.form = this.formBuilder.group({
            calcName: ['', Validators.required],
            clientName: [''],
            clientSurname: [''],
            description: [''],
        });
    }

    ngOnInit(): void {
        this.dialogRef.updateSize('544px', '560px');
    }

    public submit(): boolean {
        if (this.isCopy) {
            delete this.data.id;
        }
        if (this.form.invalid) {
            // eslint-disable-next-line
            for (const controlName in this.form.controls) {
                this.form.controls[controlName].markAsTouched();
            }
            return false;
        }

        if (!this.data.id) {
            this.data.draft = false;
            if (!this.data.inputData) {
                this.data.inputData = calculationDefaults[this.data.calculationType];
            }
            this.calcService
                .createNewCalculation(this.data)
                .pipe(untilDestroyed(this))
                .subscribe((response: any) => {
                    const sharedData = {
                        calculationType: this.data.calculationType,
                        title: this.data.calcName,
                        concept: false,
                        storable: true,
                        download: true,
                        new: true,
                        data: this.data.inputData,
                        charts: this.data.charts,
                    };
                    this.appService.setData(sharedData);
                    this.router.navigate([this.calcSettingsService.getCalcRoute(response.calculationType)], {
                        queryParams: {id: response.id},
                    });
                    this.dialogRef.close(response);
                });
        } else {
            this.data.draft = false;
            this.calcService
                .updateCalculation(this.data.id, this.data)
                .pipe(untilDestroyed(this))
                .subscribe((response: any) => {
                    this.dialogRef.close(response);
                });
        }
        return false;
    }
}
