import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CalculationService } from '../api';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../utils/confirm-dialog/confirm-dialog.component';
import { KfpCalculationService } from '../app-service/calculation.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ProbabilityParametersService } from './probability/domain/services/probability-parameters.service';
import { HistoricalParametersService } from './historical/domain/services/historical-parameters.service';
import { FinMathParametersService } from './finmath/domain/services/finmath-parameters.service';
import { TranslateService } from '@ngx-translate/core';
import {EstatesParametersService} from './estates/domain/services/estates-parameters.service';
import {MortgageParametersService} from './mortgage/services/mortgage-parameters.service';

export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class DraftGuard implements CanDeactivate<CanComponentDeactivate> {
    constructor(
        private calculationService: KfpCalculationService,
        private _probServ: ProbabilityParametersService,
        private _histServ: HistoricalParametersService,
        private _finServ: FinMathParametersService,
        private _estateServ: EstatesParametersService,
        private _mortgageServ: MortgageParametersService,
        private dialog: MatDialog,
        private translateService: TranslateService,
        private router: Router
    ) {}

    async canDeactivate(component: CanComponentDeactivate): Promise<boolean> {
        if (this.calculationService.calculation && !this.calculationService.calculation?.draft) {
            return true;
        }

        const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
            data: {
                message: this.translateService.instant('section.draft.guard.message'),
                buttonText: {
                    ok: this.translateService.instant('section.draft.guard.ok'),
                    cancel: this.translateService.instant('section.draft.guard.cancel'),
                },
            },
        });

        const confirmed = await confirmDialog.afterClosed().toPromise();

        if (confirmed) {
            this._probServ.resetParameters();
            this._histServ.resetParameters();
            this._finServ.resetParameters();
            this._estateServ.resetParameters();
            this._mortgageServ.resetParameters();

        }

        return confirmed === true;
    }
}
