import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
// import { merge } from 'lodash-es';
import { DialogComponent } from './dialog/dialog.component';
import { DialogType } from './dialog.types';

@Injectable()
export class DialogService {

    /**
     * Constructor
     */
    constructor(private _matDialog: MatDialog) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    open( data: DialogType
    ): MatDialogRef<DialogComponent> {
        // Merge the user config with the default config
        const userData = data;

        // Open the dialog
        return this._matDialog.open(DialogComponent, {
            data: userData,
            width: '45rem',
            height: '34rem',
            panelClass: 'custom-container'
        });
    }

    close(): void {
        // Open the dialog
        this._matDialog.closeAll();
    }
}
