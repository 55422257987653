import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {ErrorInterceptor} from './error.interceptor';
import {ErrorService} from './error.service';

@NgModule({
    imports  : [
        HttpClientModule
    ],
    providers: [
        ErrorService,
        {
            provide : HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi   : true
        }
    ]
})
export class ErrorModule
{
}
