import {formatNumber} from '@angular/common';
import {Injectable, Input} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {AuthService} from '../../../../auth/auth.service';
import {ChartOptions} from '../services/estates-chart.service';
import {SeriesLabel} from '../services/estates.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class LongtermEvolutionChart {
    public chart!: ChartOptions;
    logo?: string;
    @Input() height?: number;
    tooltipData?: { mortgagePayment: number[], otherExpenses: number[] };

    private dataChart = new BehaviorSubject<ChartOptions | null>(null);

    data$ = this.dataChart.asObservable();

    constructor(private authService: AuthService, public translateService: TranslateService,) {
        this.height = window.innerHeight - (window.innerHeight / 2.5);

        this.logo = this.authService.getLogoPath();
        this.dataChart.next({
            series: [],
            chart: {
                background:
                    'linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(/' + this.logo + ') no-repeat center; background-size: auto 30%;',
                type: 'line',
                height: this.height,
                width: '100%',
                toolbar: {
                    tools: {
                        download: false,
                        selection: true,
                        zoom: true, // icon hidden, styles.scss
                        zoomin: false,
                        zoomout: false,
                        pan: false,
                        reset: true,
                    },
                    autoSelected: 'zoom',
                },
                defaultLocale: 'cs',
                locales: [{
                    name: 'cs',
                    options: {
                        toolbar: {
                            reset: this.translateService.instant('apexchart.toolbar.reset'),
                            pan: this.translateService.instant('apexchart.toolbar.pan'),
                            zoomIn: this.translateService.instant('apexchart.toolbar.zoomIn'),
                            zoomOut: this.translateService.instant('apexchart.toolbar.zoomOut'),
                            selection: this.translateService.instant('apexchart.toolbar.selection'),
                            download: this.translateService.instant('apexchart.toolbar.download'),
                            exportToCSV: this.translateService.instant('apexchart.toolbar.exportToCSV')
                        }
                    }
                }],
            },
            stroke: {
                curve: ['stepline', 'stepline'],
                width: [4, 2],
            },
            colors: ['#1390e1', '#E84E0E'],
            fill: {
                type: ['gradient', 'gradient'],
                colors: ['#1390e1', '#E84E0E'],
                gradient: {
                    shade: 'light',
                    type: 'vertical',
                    shadeIntensity: 0.4,
                    opacityFrom: [0.4, 0.7],
                    opacityTo: [0.1, 0.1],
                    stops: [0, 100],
                },
            },
            markers: {
                colors: ['#E5E5EA', '#5EBAD9', '#E84E0E'],
            },
            xaxis: {
                type: 'category',
                tooltip: {
                    enabled: false,
                },
                labels: {
                    style: {
                        colors: '#95959a',
                    },
                },
            },
            yaxis: {
                forceNiceScale: true,
                decimalsInFloat: 0,
                labels: {
                    style: {
                        colors: '#95959a',
                    },
                    formatter: (value: any) => formatNumber(value, 'cs', '1.0-0') + ' ' + this.authService.currency,
                },
            },
            dataLabels: {
                offsetY: -25,
                formatter: (value: any) => formatNumber(value, 'cs', '1.0-0') + ' ' + this.authService.currency,
                style: {
                    fontSize: '12px',
                    colors: ['#1390e1', '#E84E0E'],
                },
            },
            legend: {
                show: true,
                showForSingleSeries: true,
                position: 'bottom',
                horizontalAlign: 'left',
                itemMargin: {
                    horizontal: 25,
                    vertical: 25,
                },
                markers: {
                    radius: 5,
                    width: 15,
                    height: 8,
                    offsetY: -4,
                },
                labels: {
                    colors: '#95959a',
                },
            },
            tooltip: {
                enabled: true,
                custom: (opt: any) => {
                    const dataPointIndex = opt.dataPointIndex;

                    let tooltipHTML = '<div class="apexcharts-theme-light">';
                    tooltipHTML += '<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">';
                    tooltipHTML += (+dataPointIndex) + '.' + this.translateService.instant('estates.calculation.chart.year');
                    tooltipHTML += '</div>';

                    // Profit section
                    tooltipHTML += '<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">';
                    tooltipHTML += '<span class="apexcharts-tooltip-marker" style="background-color:#5EBAD9"></span>';
                    tooltipHTML += '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">';
                    tooltipHTML += '<div class="apexcharts-tooltip-y-group">';
                    tooltipHTML += '<span class="apexcharts-tooltip-text-label">' + this.translateService.instant('estates.calculation.chart.profit') + ': </span>';
                    tooltipHTML += '<span class="apexcharts-tooltip-text-value font-semibold">' + formatNumber(opt.series[0][dataPointIndex], 'cs', '1.0-0') + '&nbsp;' + this.authService.currency + '</span>';
                    tooltipHTML += '</div>';
                    tooltipHTML += '<div class="apexcharts-tooltip-z-group">';
                    tooltipHTML += '<span class="apexcharts-tooltip-text-z-label"></span>';
                    tooltipHTML += '<span class="apexcharts-tooltip-text-z-value"></span>';
                    tooltipHTML += '</div>';
                    tooltipHTML += '</div>';
                    tooltipHTML += '</div>';

                    // Expenses section
                    tooltipHTML += '<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">';
                    tooltipHTML += '<span class="apexcharts-tooltip-marker" style="background-color:#E84E0E"></span>';
                    tooltipHTML += '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">';
                    tooltipHTML += '<div class="apexcharts-tooltip-y-group">';
                    tooltipHTML += '<span class="apexcharts-tooltip-text-label">' + this.translateService.instant('estates.calculation.chart.expenses') + ': </span>';
                    tooltipHTML += '<span class="apexcharts-tooltip-text-value font-semibold">' + formatNumber(opt.series[1][dataPointIndex], 'cs', '1.0-0') + '&nbsp;' + this.authService.currency + '</span>';
                    tooltipHTML += '</div>';
                    tooltipHTML += '<div class="apexcharts-tooltip-z-group">';
                    tooltipHTML += '<span class="apexcharts-tooltip-text-z-label"></span>';
                    tooltipHTML += '<span class="apexcharts-tooltip-text-z-value"></span>';
                    tooltipHTML += '</div>';
                    tooltipHTML += '</div>';
                    tooltipHTML += '</div>';

                    // Mortgage payment section
                    tooltipHTML += '<div class="apexcharts-tooltip-series-group apexcharts-active -my-2" style="order: 1; display: flex;">';
                    tooltipHTML += '<span class="apexcharts-tooltip-marker ml-2" style="background-color:#E84E0E; opacity: 0.8; scale: 0.5"></span>';
                    tooltipHTML += '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">';
                    tooltipHTML += '<div class="apexcharts-tooltip-y-group">';
                    tooltipHTML += '<span class="apexcharts-tooltip-text-label">' + this.translateService.instant('estates.calculation.params.mortgage_pay') + ': </span>';
                    tooltipHTML += '<span class="apexcharts-tooltip-text-value font-semibold">' + formatNumber((this.tooltipData?.mortgagePayment ? this.tooltipData.mortgagePayment[dataPointIndex] : 0), 'cs', '1.0-0') + '&nbsp;' + this.authService.currency + '</span>';
                    tooltipHTML += '</div>';
                    tooltipHTML += '<div class="apexcharts-tooltip-z-group">';
                    tooltipHTML += '<span class="apexcharts-tooltip-text-z-label"></span>';
                    tooltipHTML += '<span class="apexcharts-tooltip-text-z-value"></span>';
                    tooltipHTML += '</div>';
                    tooltipHTML += '</div>';
                    tooltipHTML += '</div>';

                    // Other expenses section
                    tooltipHTML += '<div class="apexcharts-tooltip-series-group apexcharts-active -mt-3" style="order: 1; display: flex;">';
                    tooltipHTML += '<span class="apexcharts-tooltip-marker ml-2" style="background-color:#E84E0E; opacity: 0.8; scale: 0.5"></span>';
                    tooltipHTML += '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">';
                    tooltipHTML += '<div class="apexcharts-tooltip-y-group">';
                    tooltipHTML += '<span class="apexcharts-tooltip-text-label">' + this.translateService.instant('estates.calculation.chart.other_expenses') + ': </span>';
                    tooltipHTML += '<span class="apexcharts-tooltip-text-value font-semibold">' + formatNumber((this.tooltipData?.otherExpenses ? this.tooltipData.otherExpenses[dataPointIndex] : 0), 'cs', '1.0-0') + '&nbsp;' + this.authService.currency + '</span>';
                    tooltipHTML += '</div>';
                    tooltipHTML += '<div class="apexcharts-tooltip-z-group">';
                    tooltipHTML += '<span class="apexcharts-tooltip-text-z-label"></span>';
                    tooltipHTML += '<span class="apexcharts-tooltip-text-z-value"></span>';
                    tooltipHTML += '</div>';
                    tooltipHTML += '</div>';
                    tooltipHTML += '</div>';

                    // Loop over expenses
                    const expenses = (this.tooltipData as any)?.allExpenses[dataPointIndex];
                    if (expenses) {
                        expenses.forEach((expense: any) => {
                            if (expense !== null && expense !== undefined) {
                                tooltipHTML += '<div class="apexcharts-tooltip-series-group apexcharts-active -mt-3" style="order: 1; display: flex;">';
                                tooltipHTML += '<span class="apexcharts-tooltip-marker ml-4" style="background-color:#E84E0E; opacity: 0.4; scale: 0.4"></span>';
                                tooltipHTML += '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">';
                                tooltipHTML += '<div class="apexcharts-tooltip-y-group">';
                                tooltipHTML += '<span class="apexcharts-tooltip-text-label">' + expense.name + ': </span>';
                                tooltipHTML += '<span class="apexcharts-tooltip-text-value font-semibold">' + formatNumber(expense.value, 'cs', '1.0-0') + '&nbsp;' + this.authService.currency + '</span>';
                                tooltipHTML += '</div>';
                                tooltipHTML += '<div class="apexcharts-tooltip-z-group">';
                                tooltipHTML += '<span class="apexcharts-tooltip-text-z-label"></span>';
                                tooltipHTML += '<span class="apexcharts-tooltip-text-z-value"></span>';
                                tooltipHTML += '</div>';
                                tooltipHTML += '</div>';
                                tooltipHTML += '</div>';
                            }
                        });
                    }
                    tooltipHTML += '</div>';
                    return tooltipHTML;
                }
            },

            responsive: {
                breakpoint: 1000,
                options: {
                    plotOptions: {
                        bar: {
                            horizontal: false,
                        },
                    },
                },
            },
        });
    }

    updateSeries(newValue: SeriesLabel): void {
        this.logo = this.authService.getLogoPath();

        const currentObject = this.dataChart.value;
        if (currentObject) {
            const updatedObject = {...currentObject};
            updatedObject.series = newValue.series;
            this.tooltipData = newValue.tooltipData;
            updatedObject.labels = newValue.labels;

            if (newValue.colors) {
                updatedObject.colors = newValue.colors;
            }

            if (this.logo) {
                updatedObject.chart.background =
                    'linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(/' +
                    this.logo +
                    ') no-repeat center; background-size: auto 30%;';
            }

            this.dataChart.next(updatedObject);
        }
    }

    getChartData() {
        return this.dataChart;
    }
}
