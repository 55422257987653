import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'dialog-help',
  templateUrl: 'help.component.html',
})
export class HelpComponent {
  public colors = {primary: 'darkGreen', secondary: 'brown'};

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  public notShowDialog(calculationName: string){
    localStorage.setItem('helpModal' + calculationName, JSON.stringify({show :false}));
  }

}
