import {formatNumber} from '@angular/common';
import {Injectable, Input, OnDestroy} from '@angular/core';
import {BehaviorSubject, Subject, takeUntil} from 'rxjs';
import {AuthService} from '../../../../auth/auth.service';
import {ChartOptions} from '../services/estates-chart.service';
import {SeriesLabel} from '../services/estates.service';
import {TranslateService} from '@ngx-translate/core';
import {EstatesParametersService} from '../services/estates-parameters.service';

@Injectable({
    providedIn: 'root',
})
export class InvestmentEvaluationChart implements OnDestroy {
    public chart!: ChartOptions;
    logo?: string;
    series?: SeriesLabel[];
    @Input() height?: number;

    private dataChart = new BehaviorSubject<ChartOptions | null>(null);
    private destroy$ = new Subject<void>();

    data$ = this.dataChart.asObservable();
    year = 10;

    constructor(private authService: AuthService,
                private translateService: TranslateService,
                private _estatesParametersService: EstatesParametersService) {

        this.updateChartHeight();
        this._estatesParametersService.selectedYear$.pipe(takeUntil(this.destroy$)).subscribe((selectedYear) => {
            this.changeYear(selectedYear);
            this.year = selectedYear;
        });

        this.translateService
            .stream([
                'estates.calculation.params.estateValue',
                'estates.calculation.chart.to_pay',
                'estates.calculation.chart.sum_estate',
            ])
            .subscribe((keys) => {
                this.logo = this.authService.getLogoPath();
                this.dataChart.next({
                    series: [],
                    chart: {
                        background:
                            'linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(/' + this.logo + ') no-repeat center; background-size: auto 30%;',
                        type: 'bar',
                        height: this.height,
                        width: '100%',
                        stacked: true,
                        toolbar: {
                            tools: {
                                download: false,
                                selection: true,
                                zoom: true, // icon hidden, styles.scss
                                zoomin: false,
                                zoomout: false,
                                pan: true,
                                reset: true,
                            },
                            autoSelected: 'zoom',
                        },
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true,
                            distributed: false,
                            rangeBarOverlap: true,
                            dataLabels: {
                                position: 'top',
                                hideOverflowingLabels: false,
                            },
                        },
                    },
                    colors: [
                        ({value, seriesIndex}) => {
                            if (value < 0) {
                                switch (seriesIndex) {
                                    case 0:
                                    case 1:
                                    case 2:
                                        return '#E84E0E';
                                    case 3:
                                    case 4:
                                        return 'rgba(232,78,14,0.5)';
                                    default:
                                        return '#E84E0E';
                                }
                            } else {
                                switch (seriesIndex) {
                                    case 0:
                                    case 1:
                                    case 2:
                                        return '#1390e1';
                                    case 3:
                                    case 4:
                                        return `rgba(19, 144, 225, 0.5)`;
                                    default:
                                        return '#1390e1';
                                }
                            }
                        }
                    ] as any,


                    xaxis: {
                        categories: [
                            keys['estates.calculation.params.estateValue'],
                            keys['estates.calculation.chart.to_pay'],
                            keys['estates.calculation.chart.sum_estate'],
                        ],
                        labels: {
                            style: {
                                colors: '#8E8E93',
                                fontSize: '12px',
                            },
                            formatter: (value: any) => formatNumber(value, 'cs', '1.0-0') + ' ' + this.authService.currency,

                        },
                    },
                    legend: {
                        show: false
                    },
                    yaxis: {
                        forceNiceScale: true,
                        decimalsInFloat: 0,
                        labels: {
                            style: {
                                colors: '#95959a',
                            },
                        },
                    },
                    dataLabels: {
                        enabledOnSeries: [2],
                        textAnchor: 'end',
                        // @ts-ignore
                        formatter: (_val: number, opt: any) => {
                            const series = opt.w.config.series;
                            const idx = opt.dataPointIndex;

                            // Summing up the values at the specified index across all series
                            const total = series.reduce((total1: number, self: any) => total1 + (self.data[idx] || 0), 0);

                            // Format the total value
                            const formattedTotal = formatNumber(total, 'cs', '1.0-0');

                            // Return the formatted total value along with currency
                            return formattedTotal + ' ' + this.authService.currency;
                        },

                        style: {
                            fontSize: '14px',
                            colors: ['#002B4F'],
                        },
                    },
                    annotations: {
                        xaxis: [{
                            x: 0,
                            borderColor: 'rgba(0,0,0,0.4)',
                            strokeDashArray: 0,
                        }]
                    },
                    tooltip: {
                        enabled: true,
                        y: {
                            formatter: (value: any) => formatNumber(value, 'cs', '1.0-0') + ' ' + this.authService.currency,
                        },
                        x: {
                            formatter: (value: any) => value,
                        },
                    },
                    responsive: {
                        breakpoint: 1000,
                        options: {
                            plotOptions: {
                                bar: {
                                    horizontal: false,
                                },
                            },
                        },
                    },
                });
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    updateSeries(newValue: SeriesLabel[]): void {
        this.logo = this.authService.getLogoPath();
        this.series = newValue;
        this.updateChartHeight();
        const currentObject = this.dataChart.value;
        if (currentObject) {
            const updatedObject = {...currentObject};
            updatedObject.series = newValue[0].series;
            if (newValue[0].colors) {
                updatedObject.colors = newValue[0].colors;
            }
            if (this.logo) {
                updatedObject.chart.background =
                    'linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(/' +
                    this.logo +
                    ') no-repeat center; background-size: auto 30%;';
            }
            this.changeYear(this.year);
        }
    }

    changeYear(year: number) {
        const currentObject = this.dataChart.value;
        if (currentObject && this.series) {
            const updatedObject = {...currentObject};
            updatedObject.series = this.series[year]?.series;
            this.dataChart.next(updatedObject);
        }
    }

    getChartData() {
        return this.dataChart;
    }

    updateChartHeight(): void {
        if (window.innerWidth > 1425) {
            this.height = window.innerHeight * (1 / 2);
        } else if (window.innerWidth > 995) {
            this.height = window.innerHeight * (3 / 5);
        } else {
            this.height = window.innerHeight - 430;
        }
    }
}
