import {formatNumber} from '@angular/common';
import {Injectable, Input} from '@angular/core';
import {BehaviorSubject, Subject, takeUntil} from 'rxjs';
import {AuthService} from '../../../../auth/auth.service';
import {ChartOptions} from '../services/estates-chart.service';
import {SeriesLabel} from '../services/estates.service';
import {EstatesParametersService} from '../services/estates-parameters.service';

@Injectable({
    providedIn: 'root',
})
export class InvestmentEndflowChart {
    public chart!: ChartOptions;
    logo?: string;
    series?: SeriesLabel[];
    @Input() height?: number;

    private dataChart = new BehaviorSubject<ChartOptions | null>(null);
    private destroy$ = new Subject<void>();

    data$ = this.dataChart.asObservable();
    constructor(private authService: AuthService,
                private _estatesParametersService: EstatesParametersService) {

        this._estatesParametersService.selectedYear$.pipe(takeUntil(this.destroy$)).subscribe((selectedYear) => {
            this.changeYear(selectedYear);
        });

        this.height = window.innerHeight - (window.innerHeight / 2);
        this.logo = this.authService.getLogoPath();
        this.dataChart.next({
            series: [],
            chart: {
                background:
                    'linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(/' + this.logo + ') no-repeat center; background-size: auto 30%;',
                type: 'bar',
                height: this.height,
                width: '100%',
                toolbar: {
                    tools: {
                        download: false,
                        selection: true,
                        zoom: true, // icon hidden, styles.scss
                        zoomin: false,
                        zoomout: false,
                        pan: true,
                        reset: true,
                    },
                    autoSelected: 'zoom',
                },
            },
            plotOptions: {
                bar: {
                    borderRadius: 10,
                    barHeight: '40%',
                    horizontal: false,
                    distributed: true,
                    rangeBarOverlap: true,
                    borderRadiusApplication: 'end',
                    dataLabels: {
                        position: 'top',
                        hideOverflowingLabels: false,
                    },
                },
            },
            colors: [
                ({value}) => {
                    if (value < 0) {
                        return '#E84E0E';
                    } else {
                        return '#1390e1';
                    }
                }
            ] as any,

            xaxis: {
                type: 'category',
                tooltip: {
                    enabled: false,
                },
                labels: {
                    style: {
                        colors: '#95959a',
                    },
                },
            },
            yaxis: {
                forceNiceScale: true,
                decimalsInFloat: 0,
                labels: {
                    style: {
                        colors: '#95959a',
                    },
                    formatter: (value: any) => formatNumber(value, 'cs', '1.0-0') + ' ' + this.authService.currency,
                },
            },
            dataLabels: {
                offsetY: -25,
                formatter: (value: any) => formatNumber(value, 'cs', '1.0-0') + ' ' + this.authService.currency,
                style: {
                    fontSize: '12px',
                    colors: ['#002B4F'],
                },
            },
            legend: {
                show: false,
                showForSingleSeries: true,
                position: 'bottom',
                horizontalAlign: 'left',
                itemMargin: {
                    horizontal: 25,
                    vertical: 25,
                },
                markers: {
                    radius: 5,
                    width: 15,
                    height: 8,
                    offsetY: -4,
                },
                labels: {
                    colors: '#95959a',
                },
            },
            tooltip: {
                enabled: false,
            },
            responsive: {
                breakpoint: 1000,
                options: {
                    plotOptions: {
                        bar: {
                            horizontal: false,
                        },
                    },
                },
            },
        });
    }

    updateSeries(newValue: SeriesLabel[]): void {
        this.logo = this.authService.getLogoPath();
        this.series = newValue;

        const currentObject = this.dataChart.value;
        if (currentObject) {
            const updatedObject = {...currentObject};
            updatedObject.series = newValue[0].series;
            if (newValue[0].colors) {
                updatedObject.colors = newValue[0].colors;
            }

            if (this.logo) {
                updatedObject.chart.background =
                    'linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(/' +
                    this.logo +
                    ') no-repeat center; background-size: auto 30%;';
            }
            this.dataChart.next(updatedObject);
        }
    }

    changeYear(year: number) {
        const currentObject = this.dataChart.value;
        if (currentObject && this.series) {
            const updatedObject = {...currentObject};
            updatedObject.series = this.series[year - 1]?.series;
            this.dataChart.next(updatedObject);
        }
    }

    getChartData() {
        return this.dataChart;
    }
}
