
<div class="flex flex-col justify-center items-center mt-8">
    <i class="far fa-download icon-size-12 color-primary mt-4"></i>

    <span class="text-2xl font-semibold color-secondary mt-2"> Probíhá stahování</span>
    <span class="text-secondary"> Stahování souboru PDF může chvíli trvat.</span>

    <i class="far fa-spinner animate-spin icon-size-8 mt-4"></i>
</div>


