import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, switchMap } from 'rxjs';
import { ApexAxisChartSeries } from 'ng-apexcharts';
import { CalculatedRecordContainerDto, CreateHistoryDataRequest, FinHistoryService } from 'kfp';

export type SeriesLabel = {
    series: ApexAxisChartSeries;
    labels: string[];
    min?: number;
    max?: number;
    data?: CalculatedRecordContainerDto;
};

export type ProfitVariant = 'VALUE' | 'PERCENT';

@Injectable({
    providedIn: 'root',
})
export class HistoricalService {
    constructor(
        public translateService: TranslateService,
        private _httpClient: HttpClient,
        private _historicalService: FinHistoryService
    ) {}

    dataSummary(dataRequest: CreateHistoryDataRequest): Observable<CalculatedRecordContainerDto> {
        return this._historicalService.calculateInvestmentValueMonthly(dataRequest);
    }

    dataEvolution(dataRequest: CreateHistoryDataRequest): Observable<SeriesLabel> {
        return this._historicalService.calculateInvestmentValueMonthly(dataRequest).pipe(
            switchMap((data) => {
                const series: ApexAxisChartSeries = [
                    {
                        name: this.translateService.instant('historical.calculation.chart.total_inserted'),
                        type: 'area',
                        data: data.monthlyCalculatedRecords?.map(rec => rec.depositValue) as number[],
                    },
                    {
                        name: this.translateService.instant('historical.calculation.chart.profit'),
                        type: 'line',
                        data: data.monthlyCalculatedRecords?.map(rec => rec.investmentValue - rec.depositValue) as number[],
                    },
                    {
                        name: this.translateService.instant('historical.calculation.chart.invested_funds_value'),
                        type: 'area',
                        data: data.monthlyCalculatedRecords?.map(rec => rec.investmentValue) as number[],
                    },
                ];

                const minCandidate: number[] | undefined = data.monthlyCalculatedRecords?.map(rec =>
                    Math.min(rec.investmentValue, rec.depositValue, rec.investmentValue - rec.depositValue)
                );
                const min: number = minCandidate ? Math.min(...minCandidate) : 0;

                const maxCandidate: number[] | undefined = data.monthlyCalculatedRecords?.map(rec =>
                    Math.max(rec.investmentValue, rec.depositValue, rec.investmentValue - rec.depositValue)
                );
                const max: number = maxCandidate ? Math.max(...maxCandidate) : 0;

                const labels: string[] = data.monthlyCalculatedRecords?.map(rec => rec.date + '-02') as string[];
                return of({ series: series, labels: labels, min: min, max: max, data: data } as SeriesLabel);
            })
        );
    }

    dataMarket(dataRequest: CreateHistoryDataRequest): Observable<SeriesLabel> {
        return this._historicalService.calculateInvestmentValueMonthly(dataRequest).pipe(
            switchMap((data) => {

                const series: ApexAxisChartSeries = [
                    {
                        name: this.translateService.instant('historical.calculation.chart.stock'),
                        data: data.monthlyCalculatedRecords?.map(rec => rec.investmentShares) as number[],
                    },
                    {
                        name: this.translateService.instant('historical.calculation.chart.money_market'),
                        data: data.monthlyCalculatedRecords?.map(rec => rec.investmentFinMarket) as number[],
                    },
                    {
                        name: this.translateService.instant('historical.calculation.chart.bonds'),
                        data: data.monthlyCalculatedRecords?.map(rec => rec.investmentBonds) as number[],
                    },
                    {
                        name: this.translateService.instant('historical.calculation.chart.inflation'),
                        data: data.monthlyCalculatedRecords?.map(rec => rec.investmentInflation) as number[],
                    },
                ];

                const minCandidate: number[] | undefined = data.monthlyCalculatedRecords?.map(rec =>
                    Math.min(rec.investmentInflation, rec.investmentBonds, rec.investmentFinMarket, rec.investmentShares)
                );
                const min: number = minCandidate ? Math.min(...minCandidate) : 0;

                const maxCandidate: number[] | undefined = data.monthlyCalculatedRecords?.map(rec =>
                    Math.max(rec.investmentInflation, rec.investmentBonds, rec.investmentFinMarket, rec.investmentShares)
                );
                const max: number = maxCandidate ? Math.max(...maxCandidate) : 0;

                const labels: string[] = data.monthlyCalculatedRecords?.map(rec => rec.date + '-02') as string[];

                return of({ series: series, labels: labels, min: min, max: max, data: data } as SeriesLabel);
            })
        );
    }

    dataEvaluation(dataRequest: CreateHistoryDataRequest): Observable<ApexAxisChartSeries> {
        return this._historicalService.valorization(dataRequest).pipe(
            switchMap((data) => {
                const series: ApexAxisChartSeries = [
                    {
                        data: [
                            data.sharesYearlyAverage * 100,
                            data.bondsYearlyAverage * 100,
                            data.finMarketYearlyAverage * 100,
                            data.inflationYearlyAverage * 100,
                        ],
                    },
                ];
                return of(series);
            })
        );
    }

    dataProfit(dataRequest: CreateHistoryDataRequest, profitVariant: ProfitVariant = 'PERCENT'): Observable<SeriesLabel> {
        return this._historicalService.calculateInvestmentValueYearly(dataRequest).pipe(
            switchMap((data) => {
                const series: ApexAxisChartSeries = [
                    {
                        name: this.translateService.instant('historical.calculation.chart.nominal'),
                        data: data.yearlyCalculatedRecordsNominal?.map(rec =>
                            profitVariant === 'VALUE' ? rec.profit : rec.profitsYearly * 100
                        ) as number[],
                    },
                    {
                        name: this.translateService.instant('historical.calculation.chart.real'),
                        data: data.yearlyCalculatedRecordsReal?.map(rec =>
                            profitVariant === 'VALUE' ? rec.profit : rec.profitsYearly * 100
                        ) as number[],
                    },
                ];

                const labels: string[] = data.yearlyCalculatedRecordsReal?.map(rec => rec.year.toString()) as string[];

                return of({ series: series, labels: labels, data: data } as SeriesLabel);
            })
        );
    }
}
