import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';

import {
    compactNavigation,
    defaultNavigation,
    navigationEn,
    defaultNavigationNotAdmin,
    futuristicNavigation,
    horizontalNavigation,
    horizontalNavigationNotAdmin,
    navigationSk,
} from './data';

import { FuseMockApiService, FuseNavigationItem } from 'fuseui';

@Injectable({
    providedIn: 'root',
})
export class NavigationMockApi {
    private readonly _compactNavigation: FuseNavigationItem[] = compactNavigation;
    private readonly _defaultNavigation: FuseNavigationItem[] = defaultNavigation;
    private readonly _navigationEn: FuseNavigationItem[] = navigationEn;
    private readonly _navigationSk: FuseNavigationItem[] = navigationSk;
    private readonly _futuristicNavigation: FuseNavigationItem[] = futuristicNavigation;
    private readonly _horizontalNavigation: FuseNavigationItem[] = horizontalNavigation;
    private readonly _defaultNavigationNotAdmin: FuseNavigationItem[] = defaultNavigationNotAdmin;
    private readonly _horizontalNavigationNotAdmin: FuseNavigationItem[] = horizontalNavigationNotAdmin;

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        // -----------------------------------------------------------------------------------------------------
        // @ Navigation - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService.onGet('api/common/navigation').reply(() => {
            // Fill compact navigation children using the default navigation
            this._compactNavigation.forEach((compactNavItem) => {
                this._defaultNavigation.forEach((defaultNavItem) => {
                    if (defaultNavItem.id === compactNavItem.id) {
                        compactNavItem.children = cloneDeep(defaultNavItem.children);
                    }
                });
            });

            // Fill futuristic navigation children using the default navigation
            this._futuristicNavigation.forEach((futuristicNavItem) => {
                this._defaultNavigation.forEach((defaultNavItem) => {
                    if (defaultNavItem.id === futuristicNavItem.id) {
                        futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                    }
                });
            });

            // Fill horizontal navigation children using the default navigation
            this._horizontalNavigation.forEach((horizontalNavItem) => {
                this._defaultNavigation.forEach((defaultNavItem) => {
                    if (defaultNavItem.id === horizontalNavItem.id) {
                        horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                    }
                });
            });

            // Return the response
            return [
                200,
                {
                    compact: cloneDeep(this._compactNavigation),
                    default: cloneDeep(this._defaultNavigation),
                    en: cloneDeep(this._navigationEn),
                    sk: cloneDeep(this._navigationSk),
                    futuristic: cloneDeep(this._futuristicNavigation),
                    horizontal: cloneDeep(this._horizontalNavigation),
                    defaultNotAdmin: cloneDeep(this._defaultNavigationNotAdmin),
                    horizontalNotAdmin: cloneDeep(this._horizontalNavigationNotAdmin),
                },
            ];
        });
    }
}
