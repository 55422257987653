import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {FormBuilder} from '@angular/forms';
import {DialogType} from '../dialog.types';
import {DialogService} from '../dialog.service';
import {Router} from '@angular/router';
import {UntilDestroy} from '@ngneat/until-destroy';


@UntilDestroy()
@Component({
    selector: 'fuse-confirmation-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DialogComponent implements OnInit {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogType,
        private _dialogService: DialogService,
        private formBuilder: FormBuilder,
        private _router: Router,
        private matDialog: MatDialog,
    ) {
    }

    ngOnInit(): void {
        const a = 1;
    }

}
