import { Injectable } from '@angular/core';
import { CreateFinProbabilityRequest } from 'kfp';
import moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';

export type ProfitFees = {
    bondsFee: number;
    sharesFee: number;
    finMarketFee: number;
};

export type ProbabilityParams =
    | 'shares'
    | 'bonds'
    | 'startMonth'
    | 'startYear'
    | 'yieldType'
    | 'duration'
    | 'initialInvestment'
    | 'monthlyInvestment'
    | 'investmentFee'
    | 'finMarket'
    | 'sharesAllocation'
    | 'bondsAllocation'
    | 'finMarketAllocation'
    | 'profitFees';

@Injectable({
    providedIn: 'root',
})
export class ProbabilityParametersService {
    public parameters: BehaviorSubject<CreateFinProbabilityRequest> = new BehaviorSubject<CreateFinProbabilityRequest>({
        startYear: moment(localStorage.getItem('lastDate'), 'YYYY-MM-DD').subtract(20, 'years').year(),
        startMonth: moment(localStorage.getItem('lastDate'), 'YYYY-MM-DD').subtract(20, 'years').month() + 1,
        shares: 1,
        bonds: 0,
        yieldType: 'Nominal',
        duration: 20,
        initialInvestment: 100000,
        monthlyInvestment: 3000,
        investmentFee: 0,
        finMarket: 0,
        sharesAllocation: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
        bondsAllocation: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        finMarketAllocation: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        profitFees: { bondsFee: 0, sharesFee: 0, finMarketFee: 0 },
    });
    sharedParams$: Observable<CreateFinProbabilityRequest> = this.parameters.asObservable();

    selectedHorizons = [0,2];

    public modeRatios = {
        dynamic: {
            shares: 1,
            bonds: 0,
            finMarket: 0,
        },
        balanced: {
            shares: 0.6,
            bonds: 0.4,
            finMarket: 0,
        },
        conservative: {
            shares: 0.5,
            bonds: 0.5,
            finMarket: 0,
        },
    };

    getParameters(): CreateFinProbabilityRequest {
        return this.parameters.value;
    }

    setParameters(data: CreateFinProbabilityRequest): void {
        data = this.resetAllocations(data);
        this.parameters.next(data);
    }

    private resetAllocations(data: CreateFinProbabilityRequest): CreateFinProbabilityRequest {
        data.sharesAllocation = Array(data.duration).fill(data.shares);
        data.bondsAllocation = Array(data.duration).fill(data.bonds);
        data.finMarketAllocation = Array(data.duration).fill(data.finMarket);
        return data;
    }

    public addNotice(mode: string, notice: string) {
        const parameters: any = this.getParameters();
        parameters[`${mode}_notice`] = notice;
        this.parameters.next(parameters);
    }

    public getNotice(mode: string) {
        const parameters: any = this.getParameters();
        return parameters[`${mode.toLowerCase()}_notice`];
    }

    resetParameters(): void {
        const defaultParams: CreateFinProbabilityRequest = {
            startYear: moment(localStorage.getItem('lastDate'), 'YYYY-MM-DD').subtract(20, 'years').year(),
            startMonth: moment(localStorage.getItem('lastDate'), 'YYYY-MM-DD').subtract(20, 'years').month() + 1,
            shares: 1,
            bonds: 0,
            yieldType: 'Nominal',
            duration: 20,
            initialInvestment: 100000,
            monthlyInvestment: 3000,
            investmentFee: 0,
            finMarket: 0,
            sharesAllocation: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
            bondsAllocation: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            finMarketAllocation: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            profitFees: { bondsFee: 0, sharesFee: 0, finMarketFee: 0 },
        };

        this.parameters.next(defaultParams);
    }
}
