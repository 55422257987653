<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0 relative"
     [ngClass]="{ 'pointer-events-none': downloading }"
>
    <div *ngIf="downloading" class="fixed inset-0 z-50 bg-gray-200 bg-opacity-50 pointer-events-none"></div>

    <!-- Header -->
    <div
        class="relative flex flex-0 items-center w-full h-16 px-4 z-49 shadow-lg dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden"
    >
        <!-- Navigation toggle button -->

        <div class="flex items-center basis-1/4 cursor-pointer md:block" [routerLink]="['/dashboard']">
            <img class="h-9 w-auto block" [src]="_authService.getLogoPath()"/>
        </div>
        <div class="basis-2/4 flex justify-between sm:justify-center items-center">
            <span class="text-sm md:text-lg font-medium mb-1"
                  *ngIf="activeComponent && hasUserAnySubsOrLicence$ | async">
                <div class="flex flex-col sm:flex-row" *ngIf="!nameEditing">
                    <span class="truncate">{{ activeComponent.title }} </span>
                    <span *ngIf="activeComponent?.concept"> - {{ 'section.horizontal_nav.concept' | translate }}</span>
                    <span class="ml-2 hover:cursor-pointer" *ngIf="!activeComponent?.concept && activeComponent.title"
                          (click)="editName()">
                        <i class="far fa-edit"></i>
                    </span>
                </div>
                <div class="flex flex-col sm:flex-row" *ngIf="nameEditing">
                    <input class="truncate border rounded-md p-2" [(ngModel)]="activeComponent.title"
                           [maxlength]="100"/>
                    <span class="ml-2 hover:cursor-pointer self-center" *ngIf="!activeComponent?.concept"
                          (click)="saveName()">
                        <i class="far fa-check text-lime-500"></i>
                    </span>
                </div>
            </span>
        </div>

        <div class="flex items-center basis-2/4 justify-end">
            <div class="h-16 flex items-center justify-end px-2"
                 *ngIf="activeComponent?.storable && hasUserAnySubsOrLicence$ | async">
                <div
                    [class.disabled]="saveLoading"
                    [attr.disabled]="saveLoading ? true : null"
                    class="bg-none hover:bg-primary hover:border-secondary hover:text-white border-2 border-secondary cursor-pointer text-secondary px-2 py-[4px] rounded-lg ease-in-out duration-500"
                    (click)="!saveLoading && saveData()"
                >
                    <span *ngIf="!saveLoading">{{ 'section.horizontal_nav.save' | translate }}</span>
                    <span *ngIf="saveLoading">
                        {{ 'section.horizontal_nav.save' | translate }}
                        <i class="far fa-spinner animate-spin"></i>
                    </span>
                </div>
            </div>

            <div class="h-16 flex items-center justify-end px-1" *ngIf="hasUserAnySubsOrLicence$ | async">
                <div
                    *ngIf="activeComponent?.download"
                    [class.disabled]="downloading"
                    [attr.disabled]="downloading ? true : null"
                    class="bg-none hover:bg-primary hover:border-secondary hover:text-white border-2 border-secondary cursor-pointer text-secondary px-2 py-[4px] rounded-lg ease-in-out duration-500"
                    (click)="!downloading && downloadPdf()"
                >
                    <span class="whitespace-nowrap">
                        <span class="md:hidden">
                            {{ 'section.horizontal_nav.download_pdf.short' | translate }}
                        </span>
                        <span class="hidden md:inline">
                            {{ 'section.horizontal_nav.download_pdf' | translate }}
                        </span>
                        <i *ngIf="downloading" class="ml-0.5 far fa-spinner animate-spin"></i>
                    </span>
                </div>
            </div>

            <div class="h-16 flex items-center justify-end pl-1 pr-2">
                <div
                    *ngIf="hasUserAnySubsOrLicence$ | async"
                    mat-button
                    [matMenuTriggerFor]="userMenu"
                    class="bg-primary hover:bg-secondary hover:border-secondary hover:text-white cursor-pointer text-white px-3 py-[6px] rounded-lg ease-in-out duration-500 whitespace-nowrap"
                >
                    <span class="hidden md:block">{{ 'section.horizontal_nav.new_calculation' | translate }}</span>
                    <span
                        class="md:hidden block">{{ 'section.horizontal_nav.new_calculation_short' | translate }}</span>
                </div>
                <mat-menu #userMenu="matMenu">
                    <ng-container
                        *ngIf="(companyLicence$ | async) || (activeStandardSubscription$ | async) || (activeUserLicenceDate$ | async)"
                    >
                        <button mat-menu-item (click)="createCalculation('historical')">
                            {{ 'section.horizontal_nav.historical' | translate }}
                        </button>
                        <button mat-menu-item (click)="createCalculation('probability')">
                            {{ 'section.horizontal_nav.probability' | translate }}
                        </button>
                        <button mat-menu-item (click)="createCalculation('fin_math')">
                            {{ 'section.horizontal_nav.fin_math' | translate }}
                        </button>
                    </ng-container>

                    <button
                        mat-menu-item
                        (click)="createCalculation('profit')"
                        *ngIf="
                            (flagService.isEnabled$('ff_real_estate_profit') | async) &&
                            ((activeRentPropertySubscription$ | async) ||
                                (activeUserRentPropertyLicenceDate$ | async) ||
                                (companyRentPropertyLicence$ | async))
                        "
                    >
                        {{ 'section.calculations.estates.title' | translate }}
                    </button>
                    <button
                        mat-menu-item
                        (click)="createCalculation('mortgage')"
                        *ngIf="
                            (flagService.isEnabled$('ff_mortgage_and_investment') | async) &&
                            ((activeMortgageInvestSubscription$ | async) ||
                                (activeUserMortgageInvestLicenceDate$ | async) ||
                                (companyMortgageInvestLicence$ | async))
                        "
                    >
                        {{ 'project.mortgage.title' | translate }}
                    </button>

                    <!-- ADD PRODUCT GUIDE (step-16)
                        <button
                            mat-menu-item
                            (click)="createCalculation('example')"
                            *ngIf="
                                exampleEnabled &&
                                ((activeExampleSubscription$ | async) ||
                                    (activeUserExampleLicenceDate$ | async) ||
                                    (companyExampleLicence$ | async))
                            "
                        >
                            {{ 'project.example.title' | translate }}
                        </button>
                     -->
                </mat-menu>
            </div>

            <mat-icon
                class="menu-icon kfpi-question mr-2 text-2xl cursor-pointer hover:text-primary ease-in-out duration-500"
                aria-hidden="false"
                aria-label="Example user verified icon"
                (click)="videoDialog()"
            ></mat-icon>

            <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'feather:menu'"></mat-icon>
            </button>
        </div>
    </div>

    <!-- Content -->
    <div id="layout" class="flex flex-col flex-auto">
        <router-outlet *ngIf="true"></router-outlet>
    </div>
</div>

<!-- Navigation -->
<fuse-vertical-navigation
    id="vertical-nav"
    class="bg-white light print:hidden w-full fixed right-0"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navLang"
    [opened]="!isScreenSmall"
    *ngIf="navLang"
>
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="h-18 flex items-center justify-end px-4">
            <button mat-icon-button class="scale-150" (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'feather:menu'"></mat-icon>
            </button>
        </div>

        <div class="h-16 flex items-center justify-end px-4 mr-2">
            <div>
                <button mat-icon-button [matMenuTriggerFor]="languages">
                    <ng-container *ngTemplateOutlet="flagImage; context: { $implicit: this.lang }"></ng-container>
                </button>

                <!-- Language menu -->
                <mat-menu [xPosition]="'before'" #languages="matMenu">
                    <ng-container *ngFor="let lang of availableLangs">
                        <button mat-menu-item (click)="setLanguage(lang.id.toLowerCase())">
                            <span class="flex items-center">
                                <ng-container
                                    *ngTemplateOutlet="flagImage; context: { $implicit: lang.id }"></ng-container>
                                <span class="ml-3">{{ lang.label }}</span>
                            </span>
                        </button>
                    </ng-container>
                </mat-menu>

                <!-- Flag image template -->
                <ng-template let-lang #flagImage>
                    <span class="relative w-6 shadow rounded-sm overflow-hidden">
                        <span class="absolute inset-0 ring-1 ring-inset ring-black ring-opacity-10"></span>
                        <img class="w-full" [src]="'assets/images/flags/' + flagCodes[lang]?.toUpperCase() + '.svg'"
                             [alt]="lang"/>
                    </span>
                </ng-template>
            </div>
        </div>

        <div class="items-center text-center mt-[60px]">
            <div class="flex justify-center text-2xl font-bold mt-10 mb-4" *ngIf="loggedUser">
                {{ loggedUser?.firstname + ' ' + loggedUser?.lastname }}
            </div>
            <!-- Components -->
            <div class="flex items-center ml-auto"></div>
        </div>
        <!-- User -->
        <div class="flex flex-col items-center w-full"></div>
    </ng-container>
</fuse-vertical-navigation>
