import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DomSanitizer} from '@angular/platform-browser';
import {HelpComponent} from './help/help.component';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class HelpModalService {
    private helpData: any;

    constructor(public dialog: MatDialog, private _sanitizer: DomSanitizer, private translateService: TranslateService) {
        this.translateService.onLangChange.subscribe((event) => {
            if (event.lang === 'cz') {
                this.helpData = {
                    firstLogin: {
                        title: 'Úvod',
                        video: '628954894',
                        auto: true,
                        vimeo: true,
                    },
                    main: {
                        title: 'Základní',
                        video: '579722626',
                        vimeo: true,
                    },
                    probabilityBase: {
                        title: 'Kalkulačka pravděpodobnosti',
                        video: '579719498',
                        auto: true,
                        vimeo: true,
                    },
                    probabilityBasic: {
                        title: 'Základ - průměrné zhodnocení',
                        video: '624158746',
                        vimeo: true,
                    },
                    probabilityBasicDetail: {
                        title: 'Základ - průměrné zhodnocení',
                        video: '624158746',
                        vimeo: true,
                    },
                    probability: {
                        title: 'Pravděpodobnost',
                        video: '625103737',
                        vimeo: true,
                    },
                    probabilityHistory: {
                        title: 'Porovnání',
                        video: '625103678',
                        vimeo: true,
                    },
                    probabilityProfit: {
                        title: 'Zisky',
                        video: '625103756',
                        vimeo: true,
                    },
                    probabilityFinalValue: {
                        title: 'Konečná hodnota investice',
                        video: 'https://www.youtube.com/embed/M099O8jaHWM',
                    },
                    probabilityFinalProfit: {
                        title: 'Konečný zisk',
                        video: 'https://www.youtube.com/embed/M099O8jaHWM',
                    },
                    probabilityAlocation: {
                        title: 'Alokace',
                        video: '625103529',
                        vimeo: true,
                    },
                    probabilityParameters: {
                        title: 'Parametry (jak zadat)',
                        video: '625103556',
                        vimeo: true,
                    },
                    historicalBase: {
                        title: 'Kalkulačka historie investic',
                        video: '579717907',
                        auto: true,
                        vimeo: true,
                    },
                    historicalEvaluation: {
                        title: 'Zhodnocení',
                        video: '629030429',
                        vimeo: true,
                    },
                    historicalEvolutionPortfolio: {
                        title: 'Vývoj',
                        video: '624158824',
                        vimeo: true,
                    },
                    historicalMarket: {
                        title: 'Trhy',
                        video: '624158997',
                        vimeo: true,
                    },
                    historicalProfitPercent: {
                        title: 'Zisky',
                        video: '624159087',
                        vimeo: true,
                    },
                    historicalProfitValue: {
                        title: 'Zisky',
                        video: '624159087',
                        vimeo: true,
                    },
                    historicalParameters: {
                        title: 'Parametry (jak zadat)',
                        video: '624158701',
                        vimeo: true,
                    },
                    historicalAlocation: {
                        title: 'Alokace',
                        video: '624158646',
                        vimeo: true,
                    },
                    historicalBreak: {
                        title: 'Brzda',
                        video: '629376405',
                        vimeo: true,
                    },
                    finMatBase: {
                        title: 'Kalkulačka finanční matematika',
                        video: '579715258',
                        auto: true,
                        vimeo: true,
                    },
                    finMatCredit: {
                        title: 'Vývoj hodnoty úvěru',
                        video: 'https://www.youtube.com/embed/M099O8jaHWM',
                    },
                    finMatCreditAmount: {
                        title: 'Splátka úvěru - výše úvěru',
                        video: '624158434',
                        vimeo: true,
                    },
                    finMatCreditPayment: {
                        title: 'Splátka úvěru - měsíční splátka',
                        video: '624158579',
                        vimeo: true,
                    },
                    finMatCreditBalance: {
                        title: 'Splátka úvěru - zůstatek úvěru',
                        video: '624158612',
                        vimeo: true,
                    },
                    finMatRent: {
                        title: 'Vývoj renty',
                        video: 'https://www.youtube.com/embed/M099O8jaHWM',
                    },
                    finMatRentPayment: {
                        title: 'Renta - výše renty',
                        video: '624158399',
                        vimeo: true,
                    },
                    finMatRentAmount: {
                        title: 'Renta - objem majetku',
                        video: '628956292',
                        vimeo: true,
                    },
                    finMatInvestment: {
                        title: 'Vývoj hodnoty investice',
                        video: 'https://www.youtube.com/embed/M099O8jaHWM',
                    },
                    finMatInvestmentAmount: {
                        title: 'Investice - kolik investovat',
                        video: '624158348',
                        vimeo: true,
                    },
                    finMatInvestmentPayment: {
                        title: 'Investice - kolik spořit',
                        video: '624158509',
                        vimeo: true,
                    },
                    finMatInvestmentBalance: {
                        title: 'Investice - konečná hodnota',
                        video: '624158477',
                        vimeo: true,
                    },
                    finMatInvestmentRate: {
                        title: 'Investice - efektivní úrok',
                        video: '624158551',
                        vimeo: true,
                    },
                };
            }
            if (event.lang === 'en') {
                this.helpData = {
                    firstLogin: {
                        title: 'Introduction',
                        video: '628954894',
                        auto: true,
                        vimeo: true,
                    },
                    main: {
                        title: 'Basic',
                        video: '579722626',
                        vimeo: true,
                    },
                    probabilityBase: {
                        title: 'Probability Calculator',
                        video: '579719498',
                        auto: true,
                        vimeo: true,
                    },
                    probabilityBasic: {
                        title: 'Basic - Average Returns',
                        video: '624158746',
                        vimeo: true,
                    },
                    probabilityBasicDetail: {
                        title: 'Basic - Average Returns',
                        video: '624158746',
                        vimeo: true,
                    },
                    probability: {
                        title: 'Probability',
                        video: '625103737',
                        vimeo: true,
                    },
                    probabilityHistory: {
                        title: 'Comparison',
                        video: '625103678',
                        vimeo: true,
                    },
                    probabilityProfit: {
                        title: 'Profits',
                        video: '625103756',
                        vimeo: true,
                    },
                    probabilityFinalValue: {
                        title: 'Final Investment Value',
                        video: 'https://www.youtube.com/embed/M099O8jaHWM',
                    },
                    probabilityFinalProfit: {
                        title: 'Final Profit',
                        video: 'https://www.youtube.com/embed/M099O8jaHWM',
                    },
                    probabilityAlocation: {
                        title: 'Allocation',
                        video: '625103529',
                        vimeo: true,
                    },
                    probabilityParameters: {
                        title: 'Parameters (How to Enter)',
                        video: '625103556',
                        vimeo: true,
                    },
                    historicalBase: {
                        title: 'Investment History Calculator',
                        video: '579717907',
                        auto: true,
                        vimeo: true,
                    },
                    historicalEvaluation: {
                        title: 'Evaluation',
                        video: '629030429',
                        vimeo: true,
                    },
                    historicalEvolutionPortfolio: {
                        title: 'Development',
                        video: '624158824',
                        vimeo: true,
                    },
                    historicalMarket: {
                        title: 'Markets',
                        video: '624158997',
                        vimeo: true,
                    },
                    historicalProfitPercent: {
                        title: 'Profits',
                        video: '624159087',
                        vimeo: true,
                    },
                    historicalProfitValue: {
                        title: 'Profits',
                        video: '624159087',
                        vimeo: true,
                    },
                    historicalParameters: {
                        title: 'Parameters (How to Enter)',
                        video: '624158701',
                        vimeo: true,
                    },
                    historicalAlocation: {
                        title: 'Allocation',
                        video: '624158646',
                        vimeo: true,
                    },
                    historicalBreak: {
                        title: 'Brake',
                        video: '629376405',
                        vimeo: true,
                    },
                    finMatBase: {
                        title: 'Financial Mathematics Calculator',
                        video: '579715258',
                        auto: true,
                        vimeo: true,
                    },
                    finMatCredit: {
                        title: 'Development of Loan Value',
                        video: 'https://www.youtube.com/embed/M099O8jaHWM',
                    },
                    finMatCreditAmount: {
                        title: 'Loan Payment - Loan Amount',
                        video: '624158434',
                        vimeo: true,
                    },
                    finMatCreditPayment: {
                        title: 'Loan Payment - Monthly Payment',
                        video: '624158579',
                        vimeo: true,
                    },
                    finMatCreditBalance: {
                        title: 'Loan Payment - Loan Balance',
                        video: '624158612',
                        vimeo: true,
                    },
                    finMatRent: {
                        title: 'Rent Development',
                        video: 'https://www.youtube.com/embed/M099O8jaHWM',
                    },
                    finMatRentPayment: {
                        title: 'Rent - Rent Amount',
                        video: '624158399',
                        vimeo: true,
                    },
                    finMatRentAmount: {
                        title: 'Rent - Property Volume',
                        video: '628956292',
                        vimeo: true,
                    },
                    finMatInvestment: {
                        title: 'Investment Value Development',
                        video: 'https://www.youtube.com/embed/M099O8jaHWM',
                    },
                    finMatInvestmentAmount: {
                        title: 'Investment - How Much to Invest',
                        video: '624158348',
                        vimeo: true,
                    },
                    finMatInvestmentPayment: {
                        title: 'Investment - How Much to Save',
                        video: '624158509',
                        vimeo: true,
                    },
                    finMatInvestmentBalance: {
                        title: 'Investment - Final Value',
                        video: '624158477',
                        vimeo: true,
                    },
                    finMatInvestmentRate: {
                        title: 'Investment - Effective Interest',
                        video: '624158551',
                        vimeo: true,
                    },
                };
            }
            if (event.lang === 'sk') {
                this.helpData = {
                    firstLogin: {
                        title: 'Úvod',
                        video: '628954894',
                        auto: true,
                        vimeo: true,
                    },
                    main: {
                        title: 'Základné',
                        video: '579722626',
                        vimeo: true,
                    },
                    probabilityBase: {
                        title: 'Kalkulačka pravdepodobnosti',
                        video: '579719498',
                        auto: true,
                        vimeo: true,
                    },
                    probabilityBasic: {
                        title: 'Základ - priemerné zhodnotenie',
                        video: '624158746',
                        vimeo: true,
                    },
                    probabilityBasicDetail: {
                        title: 'Základ - priemerné zhodnotenie',
                        video: '624158746',
                        vimeo: true,
                    },
                    probability: {
                        title: 'Pravdepodobnosť',
                        video: '625103737',
                        vimeo: true,
                    },
                    probabilityHistory: {
                        title: 'Porovnanie',
                        video: '625103678',
                        vimeo: true,
                    },
                    probabilityProfit: {
                        title: 'Zisky',
                        video: '625103756',
                        vimeo: true,
                    },
                    probabilityFinalValue: {
                        title: 'Konečná hodnota investície',
                        video: 'https://www.youtube.com/embed/M099O8jaHWM',
                    },
                    probabilityFinalProfit: {
                        title: 'Konečný zisk',
                        video: 'https://www.youtube.com/embed/M099O8jaHWM',
                    },
                    probabilityAlocation: {
                        title: 'Alokácia',
                        video: '625103529',
                        vimeo: true,
                    },
                    probabilityParameters: {
                        title: 'Parametre (ako zadať)',
                        video: '625103556',
                        vimeo: true,
                    },
                    historicalBase: {
                        title: 'Kalkulačka histórie investícií',
                        video: '579717907',
                        auto: true,
                        vimeo: true,
                    },
                    historicalEvaluation: {
                        title: 'Zhodnotenie',
                        video: '629030429',
                        vimeo: true,
                    },
                    historicalEvolutionPortfolio: {
                        title: 'Vývoj',
                        video: '624158824',
                        vimeo: true,
                    },
                    historicalMarket: {
                        title: 'Trhy',
                        video: '624158997',
                        vimeo: true,
                    },
                    historicalProfitPercent: {
                        title: 'Zisky',
                        video: '624159087',
                        vimeo: true,
                    },
                    historicalProfitValue: {
                        title: 'Zisky',
                        video: '624159087',
                        vimeo: true,
                    },
                    historicalParameters: {
                        title: 'Parametre (ako zadať)',
                        video: '624158701',
                        vimeo: true,
                    },
                    historicalAlocation: {
                        title: 'Alokácia',
                        video: '624158646',
                        vimeo: true,
                    },
                    historicalBreak: {
                        title: 'Brzda',
                        video: '629376405',
                        vimeo: true,
                    },
                    finMatBase: {
                        title: 'Kalkulačka finančná matematika',
                        video: '579715258',
                        auto: true,
                        vimeo: true,
                    },
                    finMatCredit: {
                        title: 'Vývoj hodnoty úveru',
                        video: 'https://www.youtube.com/embed/M099O8jaHWM',
                    },
                    finMatCreditAmount: {
                        title: 'Splátka úveru - výška úveru',
                        video: '624158434',
                        vimeo: true,
                    },
                    finMatCreditPayment: {
                        title: 'Splátka úveru - mesačná splátka',
                        video: '624158579',
                        vimeo: true,
                    },
                    finMatCreditBalance: {
                        title: 'Splátka úveru - zostatok úveru',
                        video: '624158612',
                        vimeo: true,
                    },
                    finMatRent: {
                        title: 'Vývoj renty',
                        video: 'https://www.youtube.com/embed/M099O8jaHWM',
                    },
                    finMatRentPayment: {
                        title: 'Renta - vyššie renty',
                        video: '624158399',
                        vimeo: true,
                    },
                    finMatRentAmount: {
                        title: 'Renta - objem majetku',
                        video: '628956292',
                        vimeo: true,
                    },
                    finMatInvestment: {
                        title: 'Vývoj hodnoty investície',
                        video: 'https://www.youtube.com/embed/M099O8jaHWM',
                    },
                    finMatInvestmentAmount: {
                        title: 'Investície - koľko investovať',
                        video: '624158348',
                        vimeo: true,
                    },
                    finMatInvestmentPayment: {
                        title: 'Investície - koľko sporiť',
                        video: '624158509',
                        vimeo: true,
                    },
                    finMatInvestmentBalance: {
                        title: 'Investície - konečná hodnota',
                        video: '624158477',
                        vimeo: true,
                    },
                    finMatInvestmentRate: {
                        title: 'Investície - efektívny úrok',
                        video: '624158551',
                        vimeo: true,
                    },
                };
            }
        });
        this.helpData = {
            firstLogin: {
                title: 'Úvod',
                video: '628954894',
                auto: true,
                vimeo: true,
            },
            main: {
                title: 'Základní',
                video: '579722626',
                vimeo: true,
            },
            probabilityBase: {
                title: 'Kalkulačka pravděpodobnosti',
                video: '579719498',
                auto: true,
                vimeo: true,
            },
            probabilityBasic: {
                title: 'Základ - průměrné zhodnocení',
                video: '624158746',
                vimeo: true,
            },
            probabilityBasicDetail: {
                title: 'Základ - průměrné zhodnocení',
                video: '624158746',
                vimeo: true,
            },
            probability: {
                title: 'Pravděpodobnost',
                video: '625103737',
                vimeo: true,
            },
            probabilityHistory: {
                title: 'Porovnání',
                video: '625103678',
                vimeo: true,
            },
            probabilityProfit: {
                title: 'Zisky',
                video: '625103756',
                vimeo: true,
            },
            probabilityFinalValue: {
                title: 'Konečná hodnota investice',
                video: 'https://www.youtube.com/embed/M099O8jaHWM',
            },
            probabilityFinalProfit: {
                title: 'Konečný zisk',
                video: 'https://www.youtube.com/embed/M099O8jaHWM',
            },
            probabilityAlocation: {
                title: 'Alokace',
                video: '625103529',
                vimeo: true,
            },
            probabilityParameters: {
                title: 'Parametry (jak zadat)',
                video: '625103556',
                vimeo: true,
            },
            historicalBase: {
                title: 'Kalkulačka historie investic',
                video: '579717907',
                auto: true,
                vimeo: true,
            },
            historicalEvaluation: {
                title: 'Zhodnocení',
                video: '629030429',
                vimeo: true,
            },
            historicalEvolutionPortfolio: {
                title: 'Vývoj',
                video: '624158824',
                vimeo: true,
            },
            historicalMarket: {
                title: 'Trhy',
                video: '624158997',
                vimeo: true,
            },
            historicalProfitPercent: {
                title: 'Zisky',
                video: '624159087',
                vimeo: true,
            },
            historicalProfitValue: {
                title: 'Zisky',
                video: '624159087',
                vimeo: true,
            },
            historicalParameters: {
                title: 'Parametry (jak zadat)',
                video: '624158701',
                vimeo: true,
            },
            historicalAlocation: {
                title: 'Alokace',
                video: '624158646',
                vimeo: true,
            },
            historicalBreak: {
                title: 'Brzda',
                video: '629376405',
                vimeo: true,
            },
            finMatBase: {
                title: 'Kalkulačka finanční matematika',
                video: '579715258',
                auto: true,
                vimeo: true,
            },
            finMatCredit: {
                title: 'Vývoj hodnoty úvěru',
                video: 'https://www.youtube.com/embed/M099O8jaHWM',
            },
            finMatCreditAmount: {
                title: 'Splátka úvěru - výše úvěru',
                video: '624158434',
                vimeo: true,
            },
            finMatCreditPayment: {
                title: 'Splátka úvěru - měsíční splátka',
                video: '624158579',
                vimeo: true,
            },
            finMatCreditBalance: {
                title: 'Splátka úvěru - zůstatek úvěru',
                video: '624158612',
                vimeo: true,
            },
            finMatRent: {
                title: 'Vývoj renty',
                video: 'https://www.youtube.com/embed/M099O8jaHWM',
            },
            finMatRentPayment: {
                title: 'Renta - výše renty',
                video: '624158399',
                vimeo: true,
            },
            finMatRentAmount: {
                title: 'Renta - objem majetku',
                video: '628956292',
                vimeo: true,
            },
            finMatInvestment: {
                title: 'Vývoj hodnoty investice',
                video: 'https://www.youtube.com/embed/M099O8jaHWM',
            },
            finMatInvestmentAmount: {
                title: 'Investice - kolik investovat',
                video: '624158348',
                vimeo: true,
            },
            finMatInvestmentPayment: {
                title: 'Investice - kolik spořit',
                video: '624158509',
                vimeo: true,
            },
            finMatInvestmentBalance: {
                title: 'Investice - konečná hodnota',
                video: '624158477',
                vimeo: true,
            },
            finMatInvestmentRate: {
                title: 'Investice - efektivní úrok',
                video: '624158551',
                vimeo: true,
            },
        };
    }

    public openHelp(calculationName: string) {
        if (this.helpData[calculationName]?.auto) {
            const helpModalShow = localStorage.getItem('helpModal' + calculationName);

            if (!helpModalShow) {
                localStorage.setItem('helpModal' + calculationName, JSON.stringify({show: true}));
            } else {
                const showModal = JSON.parse(helpModalShow ? helpModalShow : '');
                if (showModal !== '' && !showModal.show) {
                    return;
                }
            }
        }

        if (this.helpData[calculationName]) {
            this.dialog.open(HelpComponent, {
                data: {
                    video: this._sanitizer.bypassSecurityTrustResourceUrl(
                        this.helpData[calculationName]?.vimeo === true
                            ? 'https://player.vimeo.com/video/' +
                            this.helpData[calculationName]?.video +
                            '?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
                            : this.helpData[calculationName]?.video
                    ),
                    title: this.helpData[calculationName]?.title,
                    auto: this.helpData[calculationName]?.auto,
                    name: calculationName,
                },
                width: '50%',
                maxHeight: 'calc(100vh /1.5 )',
                height: 'auto',
                autoFocus: true,
            });
        }
    }
}
