import { formatNumber } from '@angular/common';
import { Injectable, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import moment from 'moment';
import { ChartOptions } from '../services/historical-chart.service';
import { SeriesLabel } from '../services/historical.service';
import { AuthService } from '../../../../auth/auth.service';

export type HistoricalProfitVariant = 'PERCENT' | 'VALUE';

@Injectable({
    providedIn: 'root',
})
export class ProfitChart {
    private variant: HistoricalProfitVariant = 'VALUE';
    public chart!: ChartOptions;
    logo?: string;
    @Input() height?: number;

    private dataChart = new BehaviorSubject<ChartOptions | null>(null);

    data$ = this.dataChart.asObservable();
    constructor(private authService: AuthService) {
        this.updateChartHeight();
        this.logo = this.authService.getLogoPath();
        this.dataChart.next({
            series: [],
            chart: {
                background:
                    'linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(/' + this.logo + ') no-repeat center; background-size: auto 30%;',
                type: 'bar',
                height: this.height,
                width: '100%',
                toolbar: {
                    tools: {
                        download: false,
                        selection: true,
                        zoom: true, // icon hidden, styles.scss
                        zoomin: false,
                        zoomout: false,
                        pan: false,
                        reset: true,
                    },
                    autoSelected: 'zoom',
                },
            },
            plotOptions: {
                bar: {
                    borderRadiusApplication: 'end',
                    borderRadius: 8,
                    horizontal: false,
                },
            },
            colors: ['#E84E0E', '#5EBAD9'],
            xaxis: {
                categories: [],
                type: 'datetime',
                tooltip: {
                    enabled: false,
                },
                labels: {
                    format: 'yyyy',
                    style: {
                        colors: '#95959a',
                    },
                },
            },
            yaxis: {
                forceNiceScale: true,
                decimalsInFloat: 0,
                labels: {
                    style: {
                        colors: '#95959a',
                    },
                    formatter: (value: any) =>
                        this.variant === 'VALUE' ? formatNumber(value, 'cs', '1.0-0') : formatNumber(value, 'cs', '1.0-1') + '%',
                },
            },
            dataLabels: {
                enabled: false,
            },
            legend: {
                position: 'bottom',
                horizontalAlign: 'left',
                itemMargin: {
                    horizontal: 25,
                    vertical: 25,
                },
                markers: {
                    radius: 5,
                    width: 15,
                    height: 8,
                    offsetY: -4,
                },
                labels: {
                    colors: '#95959a',
                },
            },
            tooltip: {
                enabled: true,
                x: {
                    show: true,
                    formatter: (value: any) => moment(value).format('Y'),
                },
                y: {
                    formatter: (value: any) =>
                        this.variant === 'VALUE'
                            ? formatNumber(value, 'cs', '1.0-0') + ' ' + this.authService.currency
                            : formatNumber(value, 'cs', '1.2-2') + ' %',
                },
            },
            responsive: {
                breakpoint: 1000,
                options: {
                    plotOptions: {
                        bar: {
                            horizontal: false,
                        },
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        });
    }

    updateSeries(newValue: SeriesLabel): void {
        const currentObject = this.dataChart.value;
        this.updateChartHeight();
        if (currentObject) {
            let updatedObject = { ...currentObject };
            updatedObject.series = newValue.series;
            updatedObject.chart.height = this.height;
            if (updatedObject.xaxis) {
                updatedObject.xaxis.categories = newValue.labels;
            }
            if (updatedObject?.plotOptions?.bar?.borderRadius !== undefined) {
                const barLength = updatedObject.series[0].data.length;
                switch (true) {
                    case barLength <= 15:
                        updatedObject.plotOptions.bar.borderRadius = 8;
                        break;
                    case barLength > 15 && barLength < 40:
                        updatedObject.plotOptions.bar.borderRadius = 4;
                        break;
                    case barLength >= 40:
                        updatedObject.plotOptions.bar.borderRadius = 2;
                        break;
                }
            }
            const yaxis = Object.assign({}, updatedObject.yaxis);
            yaxis.min = newValue.min;
            yaxis.max = newValue.max;
            updatedObject = { ...updatedObject, yaxis: yaxis };
            this.logo = this.authService.getLogoPath();
            this.dataChart.next(updatedObject);
        }
    }

    getChartData() {
        return this.dataChart;
    }

    updateChartHeight(): void {
        if (window.innerWidth > 1425) {
            this.height = (window.innerHeight * 70) / 100;
        } else if (window.innerWidth > 995) {
            this.height = window.innerHeight - 380;
        } else {
            this.height = window.innerHeight - 480;
        }
    }
}
