import { Injectable, Injector } from '@angular/core';
import { Observable, catchError, of, switchMap, take } from 'rxjs';
import { ProfileService } from '../../profile/profile.service';
import { FlagService, UserFlagResource, UserResource } from 'kfp';

export interface Flag {
    id: string | number;
    name: string;
    allow: boolean;
    allowFree: boolean;
    users?: Array<FlagUser>;
}

export interface FlagUser {
    id: string | number;
    email: string;
    flags: UserFlagResource[];
}

@Injectable({
    providedIn: 'root',
})
export class FlagsService {
    private flagList: Flag[] = [];
    private loggedUser?: UserResource;
    private profileService?: ProfileService;

    constructor(private injector: Injector, private readonly flagApiService: FlagService) {
        this.loadFlagListAndLoggedUser();
        document.addEventListener('sessionStorageFlagsChange', () => {
            this.loadFlagListAndLoggedUser();
        });
    }

    public loadFlagListAndLoggedUser(): void {
        const sessionFlags = sessionStorage.getItem('flags');
        if (sessionFlags) {
            this.flagList = JSON.parse(sessionFlags) as unknown as Flag[];
            this.profileService = this.injector.get(ProfileService);
            this.profileService.activeProfile().subscribe((loggedUser: UserResource) => {
                this.loggedUser = loggedUser;
            });
        }
    }

    public isEnabled$(name: string): Observable<boolean> {
        const flag = this.flagList.find((f: Flag) => f.name === name);
        let flagUser: FlagUser | undefined;
        if (this.loggedUser && flag?.users?.length && !flag?.allow) {
            flagUser = flag.users.find(u => u.id === this.loggedUser?.id && u.email === this.loggedUser?.email);
            if (flagUser?.flags) {
                return of(!!this.getUserFlag(flagUser.flags, name)?.allow);
            }
        }
        return of(!!flag?.allow);
    }

    public isEnabled(name: string): boolean {
        const flag = this.flagList.find((f: Flag) => f.name === name);
        let flagUser: FlagUser | undefined;
        if (this.loggedUser && flag?.users?.length && !flag?.allow) {
            flagUser = flag.users.find(u => u.id === this.loggedUser?.id && u.email === this.loggedUser?.email);
            if (flagUser?.flags) {
                return !!this.getUserFlag(flagUser.flags, name)?.allow;
            }
        }
        return !!flag?.allow;
    }

    public hasUserFreeAccess$(name: string): Observable<boolean> {
        const flag = this.flagList.find((f: Flag) => f.name === name);
        let flagUser: FlagUser | undefined;
        if (this.loggedUser && flag?.users?.length && !flag?.allow) {
            flagUser = flag.users.find(u => u.id === this.loggedUser?.id && u.email === this.loggedUser?.email);

            if (flagUser?.flags) {
                return of(!!this.getUserFlag(flagUser.flags, name)?.allowFree);
            }
        }

        if (flag?.allowFree) {
            return of(true);
        }

        return of(false);
    }

    public hasUserFreeAccess(name: string): boolean {
        const flag = this.flagList.find((f: Flag) => f.name === name);
        let flagUser: FlagUser | undefined;
        if (this.loggedUser && flag?.users?.length && !flag?.allow) {
            flagUser = flag.users.find(u => u.id === this.loggedUser?.id && u.email === this.loggedUser?.email);

            if (flagUser?.flags) {
                return !!this.getUserFlag(flagUser.flags, name)?.allowFree;
            }
        }

        if (flag?.allowFree) {
            return true;
        }

        return false;
    }

    public hasUserFreeAccessToAllCalc(): boolean {
        let hasAccess = false;
        ['ff_real_estate_profit', 'ff_mortgage_and_investment'].forEach((flagName: string) => {
            const flag = this.flagList.find(f => f.name === flagName);
            let freeCalc = false;
            if (flag) {
                const user = flag?.users?.find(u => u.id === this.loggedUser?.id && u.email === this.loggedUser?.email);
                if (user) {
                    freeCalc = user?.flags.find(f => f.name === flagName)?.allowFree ?? false;
                }
            }
            if (freeCalc && !this.flagList.find(f => f.name === flagName)?.allow) {
                hasAccess = true;
            }
        });
        return hasAccess;
    }

    public loadFlags(): Observable<any> {
        return this.flagApiService.getFlagLists().pipe(
            switchMap((flagList) => {
                const flags = flagList.map(flag => ({
                    id: flag.id,
                    name: flag.name,
                    allow: flag.allow,
                    allowFree: flag.allowFree,
                    users: flag.users?.map((user: any) => ({
                        id: user.id,
                        email: user.email,
                        flags: user.flags,
                    })),
                }));
                sessionStorage.setItem('flags', JSON.stringify(flags));
                this.loadFlagListAndLoggedUser();
                return of(true);
            }),
            catchError(() => of(false))
        );
    }

    getUserFlag(flags: UserFlagResource[], name: string): UserFlagResource | null {
        return flags.find(f => f.name === name) ?? null;
    }
}
