import {EventEmitter, Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {CreateFinMathRequest, CreateHistoryDataRequest} from 'kfp';


@Injectable({
    providedIn: 'root'
})
export class FinMathParametersService {

    modes = ['credit', 'investment', 'rent'];

    public preset: BehaviorSubject<any> = new BehaviorSubject<any>({
        credit: {
            futureValue: 0,                 // Zůstatek
            initialPayment: 3000000,        // Výše úvěru
            interestFrequency: 'Monthly',
            monthlyPayment: 10118,
            rate: 0.035,
            yearlyDuration: 20,
            flag: 'monthlyPayment'
        },
        investment: {
            futureValue: null,
            initialPayment: 100000,
            interestFrequency: 'Monthly',
            monthlyPayment: 5000,
            rate: 0.06,
            yearlyDuration: 20,
            flag: 'futureValue'
        },
        rent: {
            futureValue: 0,
            initialPayment: 1000000,
            interestFrequency: 'Monthly',
            monthlyPayment: 5000,
            rate: 0.04,
            yearlyDuration: 27,
            flag: 'yearlyDuration'
        }
    });
    sharedPreset$: Observable<CreateFinMathRequest> = this.preset.asObservable();
    private _mode: 'credit' | 'investment' | 'rent' = 'credit';
    private _total: any = 1000000;
    private _result: any = {credit: 0, investment: 0, rent: 0};
    private _totalExpenses: any;
    private modeSubject: BehaviorSubject<'credit' | 'investment' | 'rent'> = new BehaviorSubject(this._mode);
    mode$: Observable<'credit' | 'investment' | 'rent'> = this.modeSubject.asObservable();


    private totalSubject: BehaviorSubject<any> = new BehaviorSubject(this._total);
    total$: Observable<any> = this.totalSubject.asObservable();

    private resultSubject: BehaviorSubject<any> = new BehaviorSubject(this._result);
    result$: Observable<any> = this.resultSubject.asObservable();

    public parameters: BehaviorSubject<CreateFinMathRequest> = new BehaviorSubject<CreateFinMathRequest>(this.getParametersFromPreset());
    sharedParams$: Observable<CreateFinMathRequest> = this.parameters.asObservable();


    get mode(): 'credit' | 'investment' | 'rent' {
        return this._mode;
    }

    set mode(value: 'credit' | 'investment' | 'rent') {
        this._mode = value;
        this.modeSubject.next(value);
        this.setParameters(this.getParametersFromPreset());
    }

    get total(): any {
        return this._total;
    }

    set total(value: any) {
        this._total = value;
        this.totalSubject.next(value);
    }

    get totalExpenses(): any {
        return this._totalExpenses;
    }

    set totalExpenses(value: any) {
        this._totalExpenses = value;
    }


    getResult(mode: string): any {
        return this._result[mode];
    }

    setResult(value: any, mode: string) {
        this._result[mode] = value;
        this.resultSubject.next(this.getResult(mode));
    }

    getParameters(): CreateFinMathRequest {
        return this.parameters.value;
    }

    setParameters(data: CreateFinMathRequest): void {

        // Check each parameter for NaN or undefined
        const invalidParams = Object.entries(data)
            .filter(([key, value]) => {
                if (typeof value === 'number' && isNaN(value)) {
                    return true;
                }
                return false;
            })
            .map(([key]) => key);

        // If there are invalid parameters, don't apply the new values
        if (invalidParams.length > 0) {
            return;
        }
        // Apply new values if all parameters are valid
        this.parameters.next(data);
    }


    public setPreset(data: any): void {
        this.preset.next(data);
        this.setParameters(this.getParametersFromPreset());

    }

    public getPreset(): any {
        return this.preset.value;
    }

    public getParametersFromPreset(): any {
        const mode = this.mode;

        if (mode in this.preset.value) {
            const selectedMode = this.getPreset()[mode];
            const parameters = {...selectedMode};

            // Check if there's a flag to apply
            if (parameters.flag) {
                const flagVariable = parameters.flag;

                // Remove the flag from the parameter name
                const flaggedVariable = flagVariable + 'Flaged';

                // Add the flagged variable with the same value
                parameters[flaggedVariable] = parameters[flagVariable];

                // Remove the non-flagged variable
                delete parameters[flagVariable];

                // Remove the "flag" parameter
                delete parameters.flag;
            }

            return parameters;
        }

        return null;
    }

    public addNotice(mode: string, notice: string) {
        const preset = this.getPreset();
        preset[mode][`${mode}_notice`] = notice;
        this.setPreset(preset);

    }

    public getNotice(mode: string) {
        const preset = this.getPreset();
        return preset[mode.toLowerCase()][`${mode.toLowerCase()}_notice`];
    }


    public getAllParametersFromPreset(): any {
        const allParameters: any = {credit: {}, investment: {}, rent: {}};
        for (const mode of this.modes) {

            const selectedMode = this.getPreset()[mode];
            const parameters = {...selectedMode};

            if (parameters.flag) {
                const flagVariable = parameters.flag;
                const flaggedVariable = flagVariable + 'Flaged';
                parameters[flaggedVariable] = parameters[flagVariable];
                delete parameters[flagVariable];
                delete parameters.flag;
            }

            allParameters[mode] = parameters;

        }
        return allParameters;
    }

    resetParams() {

        this.setPreset(
            {
                credit: {
                    futureValue: 0,
                    initialPayment: 3000000,
                    interestFrequency: 'Monthly',
                    monthlyPayment: 10118,
                    rate: 0.035,
                    yearlyDuration: 20,
                    flag: 'monthlyPayment'
                },
                investment: {
                    futureValue: 0,
                    initialPayment: 100000,
                    interestFrequency: 'Monthly',
                    monthlyPayment: 5000,
                    rate: 0.06,
                    yearlyDuration: 20,
                    flag: 'futureValue'
                },
                rent: {
                    futureValue: 0,
                    initialPayment: 1000000,
                    interestFrequency: 'Monthly',
                    monthlyPayment: 5000,
                    rate: 0.04,
                    yearlyDuration: 27,
                    flag: 'yearlyDuration'
                }
            }
        );

        this._mode = 'credit';
        this._total = 1000000;
        this._result = 0;
        this._totalExpenses = null;

    }

    resetParameters(): void {
        const defaultPreset: any = {
            credit: {
                futureValue: 0,                 // Zůstatek
                initialPayment: 3000000,        // Výše úvěru
                interestFrequency: 'Monthly',
                monthlyPayment: 10118,
                rate: 0.035,
                yearlyDuration: 20,
                flag: 'monthlyPayment'
            },
            investment: {
                futureValue: null,
                initialPayment: 100000,
                interestFrequency: 'Monthly',
                monthlyPayment: 5000,
                rate: 0.06,
                yearlyDuration: 20,
                flag: 'futureValue'
            },
            rent: {
                futureValue: 0,
                initialPayment: 1000000,
                interestFrequency: 'Monthly',
                monthlyPayment: 5000,
                rate: 0.04,
                yearlyDuration: 27,
                flag: 'yearlyDuration'
            }
        };

        this.preset.next(defaultPreset);
    }

}
