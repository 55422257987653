import {Injectable, Injector} from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import {Observable, tap} from 'rxjs';
import {Router} from '@angular/router';
import {AuthService} from '../auth/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    /**
     * Constructor
     */
    constructor(private _authService: AuthService,
                private _injector: Injector,
                private _router: Router) {
    }

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(
            tap(
                event => null,
                error => this.handleError(req, error)
            ));
    }

    handleError(req: HttpRequest<any>, event: any) {

        const exceptions = ['auth/password', 'sign-in', 'register', 'download', 'remote-login', 'logout', 'logged-user', 'subscription', 'math', 'real-estate', 'financial', 'probability'];

        for (const exception of exceptions) {
            if (req.url.includes(exception)) {
                return;
            }
        }


        if (event.status === 500) {
            if (event?.error?.message === 'Unauthenticated.') {
                this._authService.signOut();
                this._router.navigate(['/sign-in']).then();
                location.reload();
            } else {
                this._router.navigate(['/500-internal-server-error']).then();
            }
        }

        if (event.status === 500) {
            this._router.navigate(['/500-internal-server-error']).then();
        }
        if (event.status === 422) {
            this._router.navigate(['/500-internal-server-error']).then();
        }
        if (event.status === 400) {
            this._router.navigate(['/404-not-found']).then();
        }
        if (event.status === 404) {
            this._router.navigate(['/404-not-found']).then();
        }
        if (event.status === 503) {
            this._router.navigate(['/maintenance']).then();
        }


    }
}
