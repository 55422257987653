import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KfpPaymentIntent, KfpStripeService, Product } from 'libs/kfp/src/lib/utils/stripe/kfpstripe.service';

import { PaymentMethod, StripeCardElement, StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, combineLatestWith, distinctUntilChanged, of, startWith, switchMap, throwError, withLatestFrom } from 'rxjs';
import { ProductResource, StripeService as StripeApiService, UserResource } from 'libs/kfp/src/lib/api';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { map, tap } from 'lodash';
import { UserService } from 'libs/kfp/src/lib/users/users.service';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
    selector: 'kfp-stripe-card',
    templateUrl: './stripe-card.component.html',
    styleUrls: ['./stripe-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StripeCardComponent implements AfterViewInit {
    error = '';
    loadPayment = false;
    public colors = { primary: 'darkGreen', secondary: 'brown' };

    cardOptions: StripeCardElementOptions = {
        iconStyle: 'solid',
        style: {
            base: {
                iconColor: '#c4f0ff',
                color: '#111111',
                fontWeight: 400,
                fontSize: '16px',
                fontSmoothing: 'antialiased',
            },
            invalid: {
                iconColor: '#E84E0E',
                color: '#E84E0E',
            },
        },
    };

    elementsOptions: StripeElementsOptions = {
        locale: 'cs',
    };

    stripeElements!: StripeCardElement;
    productData: any;
    threeDrequired = false;
    threeDUrl?: SafeResourceUrl | null;
    loading = true;
    isSubmitDisabled = true;
    couponId: string | null = null;
    isCardChecked = false;
    cardInfo = { last4: '', cardExp: '' };
    user?: UserResource;
    paymentMethodId?: string;
    @ViewChild('cardElement') cardElement!: ElementRef;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<StripeCardComponent>,
        private stripeApiService: StripeApiService,
        private kfStripeService: KfpStripeService,
        private cd: ChangeDetectorRef,
        private toastr: ToastrService,
        private userService: UserService,
        private translateService: TranslateService
    ) {
        this.productData = this.data.product;
        this.couponId = this.data.coupon;
        this.isSubmitDisabled = false;
    }

    ngAfterViewInit(): void {
        this.userService.user$.pipe(untilDestroyed(this)).subscribe((user: UserResource) => {
            this.user = user;
            if (this.user?.paymentMethodId) {
                this.cardInfo.last4 = this.user.paymentLastFour ?? '';
                this.cardInfo.cardExp = this.user.paymentExp ?? '';
                this.paymentMethodId = this.user.paymentMethodId;
                this.isCardChecked = true;
                this.loading = false;
                this.cd.markForCheck();
            } else {
                this.loadStripeElements();
            }
        });
    }

    loadStripeElements(): void {
        this.stripeElements = this.kfStripeService.getStripeCardElement();
        this.loading = false;
        this.cd.detectChanges();
        this.stripeElements.mount(this.cardElement.nativeElement);
    }

    //TO-DO save payment ID, get payment ID

    checkCard(): void {
        this.isSubmitDisabled = true;
        this.kfStripeService
            .createPayment(this.stripeElements)
            .pipe(
                switchMap((result) => {
                    this.loading = true;
                    if (result?.paymentMethod) {
                        if (result.paymentMethod.card) {
                            this.cardInfo.last4 = result.paymentMethod.card.last4;
                            this.cardInfo.cardExp =
                                result.paymentMethod.card.exp_month + '/' + +String(result.paymentMethod.card.exp_year).slice(-2);
                        }

                        return this.stripeApiService.updateUserPaymentMethod({ paymentMethod: result.paymentMethod.id });
                    }
                    return throwError(() => new Error());
                }),
                catchError(() => {
                    this.toastr.error(this.translateService.instant('section.stripe.message.error_card_check'));
                    this.loading = false;
                    this.isSubmitDisabled = false;
                    this.isCardChecked = false;
                    this.cd.markForCheck();
                    return of(null);
                })
            )
            .subscribe({
                next: (response) => {
                    if (response) {
                        if (this.user) {
                            this.user.paymentExp = this.cardInfo.cardExp;
                            this.user.paymentLastFour = this.cardInfo.last4;
                            this.user.paymentMethodId = response.paymentMethodId;
                            this.userService.user = this.user;
                        }
                        this.isCardChecked = true;
                        this.loading = false;
                        this.isSubmitDisabled = false;
                        this.toastr.success(this.translateService.instant('section.stripe.message.success_card_check'));
                        this.cd.markForCheck();
                    }
                },
            });
    }

    changeCard(): void {
        this.isCardChecked = false;
        this.paymentMethodId = undefined;
        this.loadStripeElements();
        this.cd.markForCheck();
    }

    oneTimePayment(): void {
        this.isSubmitDisabled = true;
        this.kfStripeService.$products
            .pipe(
                untilDestroyed(this),
                switchMap((products: Product[]) => {
                    this.loading = true;
                    if (this.user) {
                        this.paymentMethodId = this.user.paymentMethodId;
                        const premiumProduct = products.find((product: Product) =>
                            //temporary solution
                            product.name === 'Premium' || product.name === 'Vlastní branding aplikace' ? true : false
                        );
                        if (premiumProduct && this.paymentMethodId) {
                            //price must be in cents: for 1000 Kč = 10,0000.00
                            if (this.user.email === 'novak@techcrowd.cz') {
                                premiumProduct.price = 1500;
                            }
                            return this.stripeApiService
                                .createNewPaymentIntent({
                                    amount: premiumProduct.priceWithTax ?? premiumProduct.price,
                                    paymentMethod: this.paymentMethodId,
                                })
                                .pipe(distinctUntilChanged());
                        }
                    }
                    return of(null);
                }),
                catchError((err: any) => {
                    this.toastr.error(this.translateService.instant('section.stripe.message.error_payment'));
                    this.loading = false;
                    this.isSubmitDisabled = false;
                    this.loadStripeElements();
                    this.cd.markForCheck();
                    return of(err);
                }),
                switchMap((paymentIntentResult: any) => {
                    if (paymentIntentResult) {
                        this.toastr.info(paymentIntentResult.message);
                        this.cd.markForCheck();
                        return this.kfStripeService.processPaymentIntent(paymentIntentResult);
                    }
                    return of(null);
                })
            )
            .subscribe({
                next: (responsePaymentIntent: any) => {
                    if (responsePaymentIntent?.threeDrequired && this.loading) {
                        this.threeDrequired = true;
                        this.loading = false;
                        this.threeDUrl = responsePaymentIntent.threeDUrl;
                        this.activateUserBranding();
                        this.cd.markForCheck();
                    } else if (this.loading) {
                        this.activateUserBranding();
                        this.threeDrequired = false;
                        this.dialogRef.close(true);
                        this.toastr.success(this.translateService.instant('section.stripe.message.success_payment'));
                        this.cd.markForCheck();
                    }
                },
            });
    }

    createSubscription(): void {
        let paymentMethod: PaymentMethod;
        this.kfStripeService.$products
            .pipe(
                switchMap((products) => {
                    if (products) {
                        const standardProduct = products.find((product: Product) =>
                            //temporary solution
                            product.name === this.productData.stripeName ? true : false
                        );
                        this.loading = true;
                        this.cd.markForCheck();
                        if (this.paymentMethodId && standardProduct) {
                            return this.stripeApiService.createNewUserSubscription({
                                priceId: standardProduct.priceWithTaxId ?? standardProduct.priceId,
                                name: standardProduct?.name,
                                paymentMethod: this.paymentMethodId,
                                code: this.couponId ?? undefined,
                            });
                        }
                    }
                    return of(null);
                }),
                switchMap((paymentIntentResult: any) => {
                    if (paymentIntentResult) {
                        return this.kfStripeService.getPaymentIntent(paymentIntentResult.client_secret);
                    }
                    return of(null);
                }),
                untilDestroyed(this)
            )
            .subscribe({
                next: (responsePaymentIntent: any) => {
                    this.userService.user = null;
                    if (responsePaymentIntent?.threeDrequired && this.loading) {
                        this.threeDrequired = true;
                        this.loading = false;
                        this.threeDUrl = responsePaymentIntent.threeDUrl;
                        this.cd.markForCheck();
                    } else if (this.loading) {
                        this.threeDrequired = false;
                        this.dialogRef.close(true);
                        this.toastr.success(this.translateService.instant('section.stripe.message.subscription_created'));
                        this.cd.markForCheck();
                    }
                },
            });
    }

    createPropertyForRentSubscription(): void {
        let paymentMethod: PaymentMethod;
        this.kfStripeService.$products
            .pipe(
                switchMap((products) => {
                    if (products) {
                        const standardProduct = products.find((product: Product) =>
                            //temporary solution
                            product.name === 'Property For Rent' ? true : false
                        );
                        this.loading = true;
                        this.cd.markForCheck();
                        if (this.paymentMethodId && standardProduct) {
                            return this.stripeApiService.createNewUserSubscription({
                                priceId: standardProduct.priceWithTaxId ?? standardProduct.priceId,
                                name: standardProduct?.name,
                                paymentMethod: this.paymentMethodId,
                                code: this.couponId ?? undefined,
                            });
                        }
                    }
                    return of(null);
                }),
                switchMap((paymentIntentResult: any) => {
                    if (paymentIntentResult) {
                        return this.kfStripeService.getPaymentIntent(paymentIntentResult.client_secret);
                    }
                    return of(null);
                }),
                untilDestroyed(this)
            )
            .subscribe({
                next: (responsePaymentIntent: any) => {
                    this.userService.user = null;
                    if (responsePaymentIntent?.threeDrequired && this.loading) {
                        this.threeDrequired = true;
                        this.loading = false;
                        this.threeDUrl = responsePaymentIntent.threeDUrl;
                        this.cd.markForCheck();
                    } else if (this.loading) {
                        this.threeDrequired = false;
                        this.dialogRef.close(true);
                        this.toastr.success(this.translateService.instant('section.stripe.message.subscription_created'));
                        this.cd.markForCheck();
                    }
                },
            });
    }

    activateUserBranding(): void {
        if (this.user) {
            this.user.hasBranding = true;
            this.userService.user = this.user;
        }
    }
}
