import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CalculationService } from '../../api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { calculationDefaults, CalculationsSettingsService } from '../calculations-settings.service';
import { Router } from '@angular/router';
import { AppService } from '../../app-service/kfp.service';
import { ProbabilityParametersService } from '../probability/domain/services/probability-parameters.service';
import { ProbabilityChartService } from '../probability/domain/services/probability-chart.service';
import {ProfileService} from '../../profile/profile.service';

@UntilDestroy()
@Component({
    selector: 'download-dialog',
    templateUrl: './download-dialog.component.html',
    styleUrls: ['./download-dialog.component.scss'],
})
export class DownloadDialogComponent implements OnInit {

    constructor(
        @Inject(MAT_DIALOG_DATA) public dataRef: any,
        private dialogRef: MatDialogRef<any>,
        private readonly formBuilder: FormBuilder,
        private readonly calcService: CalculationService,
        private readonly router: Router,
        private profileService: ProfileService,
        private readonly appService: AppService,
        private readonly calcSettingsService: CalculationsSettingsService,
        private _probabilityParametersService: ProbabilityParametersService,
        private _probabilityChartService: ProbabilityChartService
    ) {}

    ngOnInit(): void {
        this.dialogRef.updateSize('500px', '300px');
    }

}
