import {formatNumber} from '@angular/common';
import {Injectable, Input, OnDestroy} from '@angular/core';
import {BehaviorSubject, Subject, takeUntil} from 'rxjs';
import {AuthService} from '../../../../auth/auth.service';
import {ChartOptions} from '../services/estates-chart.service';
import {SeriesLabel} from '../services/estates.service';
import {TranslateService} from '@ngx-translate/core';
import {EstatesParametersService} from '../services/estates-parameters.service';

@Injectable({
    providedIn: 'root',
})
export class StartCashflowChart implements OnDestroy{
    public chart!: ChartOptions;
    logo?: string;
    @Input() height?: number;
    private destroy$ = new Subject<void>();

    private dataChart = new BehaviorSubject<ChartOptions | null>(null);

    data$ = this.dataChart.asObservable();
    tooltipData: any;
    year = 10;
    series?: SeriesLabel[];

    constructor(private authService: AuthService,
                private translateService: TranslateService,
                private _estatesParametersService: EstatesParametersService) {

        this.updateChartHeight();
        this._estatesParametersService.selectedYear$.pipe(takeUntil(this.destroy$)).subscribe((selectedYear) => {
            this.changeYear(selectedYear);
            this.year = selectedYear;
        });
        this.translateService
            .stream([
                'estates.calculation.chart.profit',
                'estates.calculation.params.expenses',
                'estates.calculation.params.cashflow',
            ])
            .subscribe((keys) => {
                this.logo = this.authService.getLogoPath();
                this.dataChart.next({
                    series: [],
                    chart: {
                        background:
                            'linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(/' + this.logo + ') no-repeat center; background-size: auto 30%;',
                        type: 'bar',
                        height: this.height,
                        width: '100%',
                        stacked: true,
                        toolbar: {
                            tools: {
                                download: false,
                                selection: true,
                                zoom: true, // icon hidden, styles.scss
                                zoomin: false,
                                zoomout: false,
                                pan: true,
                                reset: true,
                            },
                            autoSelected: 'zoom',
                        },
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true,
                            distributed: false,
                            rangeBarOverlap: true,
                            dataLabels: {
                                position: 'top',
                                hideOverflowingLabels: false,
                            },
                        },
                    },
                    colors: [
                        // distinction for stacked bar & positive vs negative values
                        ({value, seriesIndex}) => {
                            if (value < 0) {
                                return seriesIndex === 2 ? `rgba(232, 78, 14, 0.5)` : '#E84E0E';
                            } else {
                                return seriesIndex === 2 ? `rgba(19, 144, 225, 0.5)` : '#1390e1';
                            }
                        }
                    ] as any,


                    xaxis: {
                        categories: [
                            keys['estates.calculation.chart.profit'],
                            keys['estates.calculation.params.expenses'],
                            keys['estates.calculation.params.cashflow'],
                        ],
                        labels: {
                            style: {
                                colors: '#8E8E93',
                                fontSize: '12px',
                            },
                            formatter: (value: any) => formatNumber(value, 'cs', '1.0-0') + ' ' + this.authService.currency,

                        },
                    },
                    legend: {
                        show: false
                    },
                    yaxis: {
                        forceNiceScale: true,
                        decimalsInFloat: 0,
                        labels: {
                            style: {
                                colors: '#95959a',
                            },
                        },
                    },
                    annotations: {
                        xaxis: [{
                            x: 0,
                            borderColor: 'rgba(0,0,0,0.4)',
                            strokeDashArray: 0,
                        }]
                    },
                    dataLabels: {
                        enabledOnSeries: [2],
                        textAnchor: 'end',
                        // @ts-ignore
                        formatter: (_val: number, opt: any) => {
                            const series = opt.w.config.series;
                            const idx = opt.dataPointIndex;

                            // Summing up the values at the specified index across all series
                            const total = series.reduce((total1: number, self: any) => total1 + (self.data[idx] || 0), 0);

                            // Format the total value
                            const formattedTotal = formatNumber(total, 'cs', '1.0-0');

                            // Return the formatted total value along with currency
                            return formattedTotal + ' ' + this.authService.currency;
                        },

                        style: {
                            fontSize: '12px',
                            colors: ['#002B4F'],
                        },
                    },
                    tooltip: {
                        enabled: true,
                        custom: (opt: any) => {

                            const barData = opt.ctx.opts.series[opt.seriesIndex].data[opt.dataPointIndex];
                            const seriesColor = opt.ctx.opts.colors[0]({value: barData, seriesIndex: opt.seriesIndex});

                            let tooltipHTML = '<div class="apexcharts-theme-light">';
                            tooltipHTML += '<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 14px;">';
                            tooltipHTML += opt.ctx.opts.series[opt.seriesIndex].name;
                            tooltipHTML += '</div>';

                            // Incomes
                            if (opt.seriesIndex === 0) {
                                tooltipHTML += '<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">';
                                tooltipHTML += '<span class="apexcharts-tooltip-marker ml-2" style="background-color:' + seriesColor + '; opacity: 0.8; scale: 0.5"></span>';
                                tooltipHTML += '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 14px;">';
                                tooltipHTML += '<div class="apexcharts-tooltip-y-group">';
                                tooltipHTML += '<span class="apexcharts-tooltip-text-label">' + this.translateService.instant('estates.calculation.chart.profit') + ': </span>';
                                tooltipHTML += '<span class="apexcharts-tooltip-text-value font-semibold">' + formatNumber((this.tooltipData?.incomes ? this.tooltipData.incomes : 0), 'cs', '1.0-0') + '&nbsp;' + this.authService.currency + '</span>';
                                tooltipHTML += '</div>';
                                tooltipHTML += '<div class="apexcharts-tooltip-z-group">';
                                tooltipHTML += '<span class="apexcharts-tooltip-text-z-label"></span>';
                                tooltipHTML += '<span class="apexcharts-tooltip-text-z-value"></span>';
                                tooltipHTML += '</div>';
                                tooltipHTML += '</div>';
                                tooltipHTML += '</div>';
                            }
                            // Hypo
                            if (opt.seriesIndex === 1) {
                                tooltipHTML += '<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">';
                                tooltipHTML += '<span class="apexcharts-tooltip-marker ml-2" style="background-color:' + seriesColor + '; opacity: 0.8; scale: 0.5"></span>';
                                tooltipHTML += '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 14px;">';
                                tooltipHTML += '<div class="apexcharts-tooltip-y-group">';
                                tooltipHTML += '<span class="apexcharts-tooltip-text-label">' + this.translateService.instant('estates.calculation.tab.mortgage') + ': </span>';
                                tooltipHTML += '<span class="apexcharts-tooltip-text-value font-semibold">' + formatNumber((this.tooltipData?.hypo ? -this.tooltipData.hypo : 0), 'cs', '1.0-0') + '&nbsp;' + this.authService.currency + '</span>';
                                tooltipHTML += '</div>';
                                tooltipHTML += '<div class="apexcharts-tooltip-z-group">';
                                tooltipHTML += '<span class="apexcharts-tooltip-text-z-label"></span>';
                                tooltipHTML += '<span class="apexcharts-tooltip-text-z-value"></span>';
                                tooltipHTML += '</div>';
                                tooltipHTML += '</div>';
                                tooltipHTML += '</div>';
                            }
                            if (opt.seriesIndex === 2) {
                                // Other expenses section
                                tooltipHTML += '<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">';
                                tooltipHTML += '<span class="apexcharts-tooltip-marker ml-2" style="background-color:' + seriesColor + '; opacity: 0.8; scale: 0.5"></span>';
                                tooltipHTML += '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 14px;">';
                                tooltipHTML += '<div class="apexcharts-tooltip-y-group">';
                                tooltipHTML += '<span class="apexcharts-tooltip-text-label">' + this.translateService.instant('estates.calculation.chart.other_expenses') + ': </span>';
                                tooltipHTML += '<span class="apexcharts-tooltip-text-value font-semibold">' + formatNumber((this.tooltipData?.otherExpenses ? this.tooltipData.otherExpenses : 0), 'cs', '1.0-0') + '&nbsp;' + this.authService.currency + '</span>';
                                tooltipHTML += '</div>';
                                tooltipHTML += '<div class="apexcharts-tooltip-z-group">';
                                tooltipHTML += '<span class="apexcharts-tooltip-text-z-label"></span>';
                                tooltipHTML += '<span class="apexcharts-tooltip-text-z-value"></span>';
                                tooltipHTML += '</div>';
                                tooltipHTML += '</div>';
                                tooltipHTML += '</div>';

                                // Loop over expenses
                                const expenses = (this.tooltipData as any)?.allExpenses;
                                if (expenses) {
                                    expenses.forEach((expense: any) => {
                                        if (expense !== null && expense !== undefined) {
                                            tooltipHTML += '<div class="apexcharts-tooltip-series-group apexcharts-active -mt-3" style="order: 1; display: flex;">';
                                            tooltipHTML += '<span class="apexcharts-tooltip-marker ml-4" style="background-color:' + seriesColor + '; opacity: 0.4; scale: 0.4"></span>';
                                            tooltipHTML += '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 14px;">';
                                            tooltipHTML += '<div class="apexcharts-tooltip-y-group">';
                                            tooltipHTML += '<span class="apexcharts-tooltip-text-label">' + expense.name + ': </span>';
                                            tooltipHTML += '<span class="apexcharts-tooltip-text-value font-semibold">' + formatNumber(expense.value, 'cs', '1.0-0') + '&nbsp;' + this.authService.currency + '</span>';
                                            tooltipHTML += '</div>';
                                            tooltipHTML += '<div class="apexcharts-tooltip-z-group">';
                                            tooltipHTML += '<span class="apexcharts-tooltip-text-z-label"></span>';
                                            tooltipHTML += '<span class="apexcharts-tooltip-text-z-value"></span>';
                                            tooltipHTML += '</div>';
                                            tooltipHTML += '</div>';
                                            tooltipHTML += '</div>';
                                        }
                                    });
                                }
                            }
                            // sum
                            if (opt.seriesIndex === 3) {
                                tooltipHTML += '<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">';
                                tooltipHTML += '<span class="apexcharts-tooltip-marker ml-2" style="background-color:' + seriesColor + '; opacity: 0.8; scale: 0.5"></span>';
                                tooltipHTML += '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 14px;">';
                                tooltipHTML += '<div class="apexcharts-tooltip-y-group">';
                                tooltipHTML += '<span class="apexcharts-tooltip-text-label">' + this.translateService.instant('estates.calculation.params.cashflow') + ': </span>';
                                tooltipHTML += '<span class="apexcharts-tooltip-text-value font-semibold">' + formatNumber((this.tooltipData?.result ? this.tooltipData.result : 0), 'cs', '1.0-0') + '&nbsp;' + this.authService.currency + '</span>';
                                tooltipHTML += '</div>';
                                tooltipHTML += '<div class="apexcharts-tooltip-z-group">';
                                tooltipHTML += '<span class="apexcharts-tooltip-text-z-label"></span>';
                                tooltipHTML += '<span class="apexcharts-tooltip-text-z-value"></span>';
                                tooltipHTML += '</div>';
                                tooltipHTML += '</div>';
                                tooltipHTML += '</div>';
                            }

                            tooltipHTML += '</div>';
                            return tooltipHTML;
                        }
                    },

                    responsive: {
                        breakpoint: 1000,
                        options: {
                            plotOptions: {
                                bar: {
                                    horizontal: false,
                                },
                            },
                        },
                    },
                });
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    updateSeries(newValue: SeriesLabel[]): void {
        this.logo = this.authService.getLogoPath();
        this.series = newValue;
        this.updateChartHeight();
        const currentObject = this.dataChart.value;
        if (currentObject) {
            const updatedObject = {...currentObject};
            updatedObject.series = newValue[this.year].series;
            this.tooltipData = newValue[this.year].tooltipData;
            if (newValue[0].colors) {
                updatedObject.colors = newValue[0].colors;
            }
            if (this.logo) {
                updatedObject.chart.background =
                    'linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(/' +
                    this.logo +
                    ') no-repeat center; background-size: auto 30%;';
            }
            this.changeYear(this.year);
        }
    }

    getChartData() {
        return this.dataChart;
    }

    updateChartHeight(): void {
        if (window.innerWidth > 1425) {
            this.height = window.innerHeight * (1 / 2);
        } else if (window.innerWidth > 995) {
            this.height = window.innerHeight * (3 / 5);
        } else {
            this.height = window.innerHeight - 430;
        }
    }

    changeYear(year: number) {
        const currentObject = this.dataChart.value;
        if (currentObject && this.series) {
            const updatedObject = {...currentObject};
            updatedObject.series = this.series[year]?.series;
            this.tooltipData = this.series[year]?.tooltipData;
            this.dataChart.next(updatedObject);
        }
    }
}
