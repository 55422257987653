import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of, switchMap} from 'rxjs';
import {ApexAxisChartSeries} from 'ng-apexcharts';
import {CalculatedFinMathContainer, CalculationService, CreateFinMathRequest, FinMathService} from 'kfp';
import {FinMathParametersService} from './finmath-parameters.service';

export type SeriesLabel = {
    series: ApexAxisChartSeries;
    labels: string[];
    min?: number;
    max?: number;
    data?: CalculatedFinMathContainer;
};

@Injectable({
    providedIn: 'root',
})
export class FinMathKfpService {
    constructor(
        public translateService: TranslateService,
        private _httpClient: HttpClient,
        private _finMathService: FinMathService,
        private _finMathParametersService: FinMathParametersService,
        private _calcService: CalculationService
    ) {
    }

    dataCredit(dataRequest: CreateFinMathRequest, updateTotal: boolean): Observable<SeriesLabel> {
        return this._finMathService.calculateMortgage(dataRequest).pipe(
            switchMap((data: any) => {
                if (!data || !data.records || !data.records.length) {
                    // Handle empty data case
                    if (updateTotal) {
                        this._finMathParametersService.setResult([], 'credit');
                        this._finMathParametersService.total = 0;
                    }

                    const emptySeries: ApexAxisChartSeries = [
                        {
                            name: this.translateService.instant('fin_math.calculation.tab.parameters.payments_left'),
                            type: 'area',
                            data: [],
                        },
                    ];

                    return of({series: emptySeries, labels: [], min: 0, max: 0, data: data} as SeriesLabel);
                }

                // Handle non-empty data case
                if (updateTotal) {
                    this._finMathParametersService.setResult(data.result, 'credit');
                    this._finMathParametersService.total = data.records[data.records.length - 1]?.expenses || 0;
                }

                const series: ApexAxisChartSeries = [
                    {
                        name: this.translateService.instant('fin_math.calculation.tab.parameters.payments_left'),
                        type: 'area',
                        data: data.records.map(rec => rec.investmentValue) as number[],
                    },
                ];

                const minCandidate: number[] = data.records.map(rec => rec.investmentValue);
                const min: number = minCandidate.length ? Math.min(...minCandidate) : 0;

                const maxCandidate: number[] = data.records.map(rec => rec.investmentValue);
                const max: number = maxCandidate.length ? Math.max(...maxCandidate) : 0;

                const labels: string[] = data.records.map(rec => rec.yearIndex + '') as string[];

                return of({series: series, labels: labels, min: min, max: max, data: data} as SeriesLabel);
            })
        );
    }

    dataInvestment(dataRequest: CreateFinMathRequest, updateTotal: boolean): Observable<SeriesLabel> {
        return this._finMathService.calculateInvestment(dataRequest).pipe(
            switchMap((data: any) => {
                if (!data || !data.records || !data.records.length) {
                    // Handle empty data case
                    if (updateTotal) {
                        this._finMathParametersService.setResult([], 'investment');
                        this._finMathParametersService.total = 0;
                        this._finMathParametersService.totalExpenses = 0;
                    }

                    const emptySeries: ApexAxisChartSeries = [
                        {
                            name: this.translateService.instant('fin_math.calculation.tab.parameters.investment__value'),
                            type: 'line',
                            data: [],
                        },
                        {
                            name: this.translateService.instant('fin_math.calculation.tab.parameters.total_inserted'),
                            type: 'area',
                            data: [],
                        },
                    ];

                    return of({series: emptySeries, labels: [], min: 0, max: 0, data: data} as SeriesLabel);
                }

                // Handle non-empty data case
                if (updateTotal) {
                    this._finMathParametersService.setResult(data.result, 'investment');
                    this._finMathParametersService.total = data.records[data.records.length - 1]?.investmentValue || 0;
                    this._finMathParametersService.totalExpenses = data.records[data.records.length - 1]?.expenses || 0;
                }
                const series: ApexAxisChartSeries = [
                    {
                        name: this.translateService.instant('fin_math.calculation.tab.parameters.investment__value'),
                        type: 'line',
                        data: data.records.map(rec => rec.investmentValue) as number[],
                    },
                    {
                        name: this.translateService.instant('fin_math.calculation.tab.parameters.total_inserted'),
                        type: 'area',
                        data: data.records.map(rec => rec.expenses) as number[],
                    },
                ];

                const minCandidate: number[] = data.records.map(rec => rec.investmentValue);
                const min: number = minCandidate.length ? Math.min(...minCandidate) : 0;

                const maxCandidate: number[] = data.records.map(rec => rec.investmentValue);
                const max: number = maxCandidate.length ? Math.max(...maxCandidate) : 0;

                const labels: string[] = data.records.map(rec => rec.yearIndex + '') as string[];

                return of({series: series, labels: labels, min: min, max: max, data: data} as SeriesLabel);
            })
        );
    }

    dataRent(dataRequest: CreateFinMathRequest, updateTotal: boolean): Observable<SeriesLabel> {
        return this._finMathService.calculateRent(dataRequest).pipe(
            switchMap((data: any) => {
                if (!data || !data.records || !data.records.length) {
                    // Handle empty data case

                    if (updateTotal) {
                        this._finMathParametersService.setResult([], 'rent');
                        this._finMathParametersService.total = 0;
                    }

                    const emptySeries: ApexAxisChartSeries = [
                        {
                            name: this.translateService.instant('fin_math.calculation.tab.parameters.balance'),
                            type: 'area',
                            data: [],
                        },
                    ];

                    return of({series: emptySeries, labels: [], min: 0, max: 0, data: data} as SeriesLabel);
                }

                // Handle non-empty data case
                if (updateTotal) {
                    this._finMathParametersService.setResult(data.result, 'rent');
                    this._finMathParametersService.total = data.records[data.records.length - 1]?.expenses || 0;

                }
                const series: ApexAxisChartSeries = [
                    {
                        name: this.translateService.instant('fin_math.calculation.tab.parameters.balance'),
                        type: 'area',
                        data: data.records.map(rec => rec.investmentValue) as number[],
                    },
                ];

                const minCandidate: number[] = data.records.map(rec => rec.investmentValue);
                const min: number = minCandidate.length ? Math.min(...minCandidate) : 0;

                const maxCandidate: number[] = data.records.map(rec => rec.investmentValue);
                const max: number = maxCandidate.length ? Math.max(...maxCandidate) : 0;

                const labels: string[] = data.records.map(rec => rec.yearIndex + '') as string[];

                return of({series: series, labels: labels, min: min, max: max, data: data} as SeriesLabel);
            })
        );
    }

}
