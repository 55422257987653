<!-- Button -->
<button class="flex flex-row items-center"
    [matMenuTriggerFor]="userActions">


      <!-- <div class="mr-2">{{user?.firstname}} {{user?.lastname}}</div> -->

      <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>

</button>

<mat-menu
    [xPosition]="'before'"
    #userActions="matMenu">
    <button mat-menu-item (click)="userProfile()">
        <span class="flex flex-row items-center leading-none">
                <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>

              {{user?.firstname}} {{user?.lastname}}

        </span>
    </button>
    <!-- <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item
            (click)="userProfile()">
        <i class="far mr-2 fa-user-circle"></i>
        <span>Profil</span>
    </button>
    <button mat-menu-item
            (click)="settings()">
        <i class="far mr-2 fa-cog"></i>
        <span>Nastavení</span>
    </button> -->
    <mat-divider class="my-2"></mat-divider>
    <button
        mat-menu-item
        (click)="signOut()">
        <i class="far mr-2 fa-sign-out-alt"></i>
        <span>Odhlásit se</span>
    </button>
</mat-menu>
