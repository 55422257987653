import { formatNumber } from '@angular/common';
import { Injectable, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import moment from 'moment';
import { ChartOptions } from '../services/historical-chart.service';
import { SeriesLabel } from '../services/historical.service';
import { AuthService } from '../../../../auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class MarketChart {
    public chart!: ChartOptions;
    logo?: string;
    @Input() height?: number;

    private dataChart = new BehaviorSubject<ChartOptions | null>(null);

    data$ = this.dataChart.asObservable();

    constructor(private authService: AuthService) {
        if (window.innerWidth > 1425) {
            this.height = (window.innerHeight * 70) / 100;
        } else {
            this.height = (window.innerHeight * 65) / 100 - 75;
        }
        this.logo = this.authService.getLogoPath();
        this.dataChart.next({
            series: [],
            chart: {
                type: 'line',
                background:
                    'linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(/' + this.logo + ') no-repeat center; background-size: auto 30%;',
                width: '100%',
                stacked: false,
                height: this.height,
                zoom: {
                    type: 'xy',
                    enabled: true,
                    autoScaleYaxis: true,
                },
                toolbar: {
                    tools: {
                        download: false,
                        selection: true,
                        zoom: true, // icon hidden, styles.scss
                        zoomin: false,
                        zoomout: false,
                        pan: false,
                        reset: true,
                    },
                    autoSelected: 'zoom',
                },
                // events: {
                //     zoomed: (chartContext, { xaxis, yaxis }) => {
                //         const chart = chartContext;
                //         const seriesData = chart.w.globals.series.slice(); // Get the entire series data
                //         const xMin = xaxis.min;
                //         const xMax = xaxis.max;
                //
                //         // Filter data based on the visible x-axis range
                //         const visibleData = seriesData.map((series: any) => series.filter((dataPoint: any, index: any) => {
                //                 const xValue = chart.w.globals.labels[index];
                //                 return xValue >= xMin && xValue <= xMax;
                //             }));
                //
                //     }
                // }
            },
            fill: {
                type: ['solid', 'solid', 'solid', 'solid'],
                opacity: [1, 1, 1, 1],
                colors: ['#E44D25', '#FBBC05', '#002B4F', '#7850EB'],
            },
            colors: ['#E44D25', '#FBBC05', '#002B4F', '#7850EB'],
            stroke: {
                curve: ['smooth', 'smooth', 'smooth', 'smooth'],
                width: [4, 4, 4, 4],
            },
            markers: {
                colors: ['#E44D25', '#FBBC05', '#002B4F', '#7850EB'],
            },
            xaxis: {
                categories: [],
                type: 'datetime',
                tooltip: {
                    enabled: false,
                },
                labels: {
                    format: 'yyyy',
                    style: {
                        colors: '#95959a',
                    },
                },
            },
            yaxis: {
                labels: {
                    style: {
                        colors: '#95959a',
                    },
                    formatter: (value: any) => formatNumber(value, 'cs', '1.1-3'),
                },
            },
            tooltip: {
                shared: true,
                intersect: false,
                x: {
                    show: true,
                    formatter: (value: any) => moment(value).format('MMMM  Y'),
                },
            },
            legend: {
                position: 'bottom',
                horizontalAlign: 'left',
                itemMargin: {
                    horizontal: 25,
                    vertical: 25,
                },
                markers: {
                    radius: 5,
                    width: 15,
                    height: 8,
                    offsetY: -4,
                },
                labels: {
                    colors: '#95959a',
                },
            },
        });
    }

    updateSeries(newValue: SeriesLabel): void {
        const currentObject = this.dataChart.value;
        if (currentObject) {
            let updatedObject = { ...currentObject };
            updatedObject.series = newValue.series;
            if (updatedObject.xaxis) {
                updatedObject.xaxis.categories = newValue.labels;
            }
            const yaxis = Object.assign({}, updatedObject.yaxis);
            yaxis.min = newValue.min;
            yaxis.max = newValue.max;
            updatedObject = { ...updatedObject, yaxis: yaxis };
            this.logo = this.authService.getLogoPath();
            this.dataChart.next(updatedObject);
        }
    }

    getChartData() {
        return this.dataChart;
    }
}
