import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActiveComponent } from './active-component';

@Injectable({
    providedIn: 'root',
})
export class AppService {
    private sharedDataSubject: BehaviorSubject<ActiveComponent | null> = new BehaviorSubject<ActiveComponent | null>(null);

    // Observable pro sledování změn dat
    sharedData$: Observable<ActiveComponent | null> = this.sharedDataSubject.asObservable();

    // Metoda pro nastavení dat
    setData(data: ActiveComponent | null): void {
        this.sharedDataSubject.next(data);
    }

    // Metoda pro úpravu titulku
    updateTitle(newValue: string): void {
        const currentObject = this.sharedDataSubject.value;

        if (currentObject) {
            const updatedObject = { ...currentObject };
            updatedObject.title = newValue;
            this.sharedDataSubject.next(updatedObject);
        }
    }

    // Metoda pro úpravu
    updateConcept(newValue: boolean): void {
        const currentObject = this.sharedDataSubject.value;

        if (currentObject) {
            const updatedObject = { ...currentObject };
            updatedObject.concept = newValue;
            this.sharedDataSubject.next(updatedObject);
        }
    }

    // Metoda pro úpravu
    updateData(newValue: any): void {
        const currentObject = this.sharedDataSubject.value;

        if (currentObject) {
            const updatedObject = { ...currentObject };
            updatedObject.data = newValue;
            this.sharedDataSubject.next(updatedObject);
        }
    }

    // Metoda pro získání aktuálních dat
    getData(): ActiveComponent | null {
        return this.sharedDataSubject.value;
    }

    changePrimaryColor(newColor: string): void {
        localStorage.setItem('color', newColor);
        document.documentElement.style.setProperty('--primary', newColor);
    }
}
