import {APP_INITIALIZER, ErrorHandler, Injectable, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {ExtraOptions, NoPreloading, Router, RouterModule} from '@angular/router';
import { FuseConfigModule, FuseMockApiModule, FuseModule } from 'fuseui';
import { appConfig } from './core/config/app.config';

import { AppComponent } from './app.component';
import { appRoutes } from './app.routing';
import { CoreModule } from './core/core.module';
import { LayoutModule } from 'kfp';
import { mockApiServices } from './mock-api';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { KfpModule } from '../../../../libs/kfp/src/lib/kfp.module';
import { ToastrModule } from 'ngx-toastr';
import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { registerLocaleData } from '@angular/common';
import localeCs from '@angular/common/locales/cs';
import { TimeagoCustomFormatter, TimeagoFormatter, TimeagoIntl, TimeagoModule } from 'ngx-timeago';
import { environment } from '../environments/environment';
import { NgxStripeModule } from 'ngx-stripe';
import * as Sentry from '@sentry/angular-ivy';

const routerConfig: ExtraOptions = {
    preloadingStrategy: NoPreloading,
    scrollPositionRestoration: 'enabled',
};

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + Date.now());
}

registerLocaleData(localeCs);

@Injectable()
export class MyIntl extends TimeagoIntl {
    strings = {
        prefixAgo: 'před',
        hour: '1 hodinou',
        hours: '%d hodinami',
        minute: '1 minutou',
        minutes: '%d minutami',
        second: '1 sekundou',
        seconds: '%d sekundami',
        month: '1 měsícem',
        months: '%d měsíci',
        year: '1 rokem',
        years: '%d lety',
        day: '1 rokem',
        days: '%d dny',
    };
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        KfpModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),
        // Core module of your application
        CoreModule,
        // Layout module of your application
        LayoutModule,

        BrowserAnimationsModule, // required animations module
        ToastrModule.forRoot(), // ToastrModule added
        NgxStripeModule.forRoot(environment.stripeKey),

        // Translate module
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),

        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyCFENVfy-_IPw3UrFDQWom5oK7MsmiSjWY',
            libraries: ['places'],
            apiVersion: '4',
            language: 'cs',
        }),
        MatGoogleMapsAutocompleteModule,
        TimeagoModule.forRoot({
            intl: { provide: TimeagoIntl, useClass: MyIntl },
            formatter: { provide: TimeagoFormatter, useClass: TimeagoCustomFormatter },
        }),
    ],
    providers: [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
