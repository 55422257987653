import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { DialogService } from './dialog.service';
import { DialogComponent } from './dialog/dialog.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
    declarations: [DialogComponent],
    imports: [
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        MatSelectModule,
        MatInputModule,
        MatFormFieldModule,
    ],
    providers: [DialogService],
})
export class DialogModule {
    /**
     * Constructor
     */
    constructor(private _dialogService: DialogService) {}
}
