<div class="text-2xl font-semibold">{{ data.title }}</div>
<mat-dialog-content class="mat-typography">
    <div style="padding: 56.25% 0 0 0; position: relative">
        <iframe
            [src]="data.video"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            class="absolute top-0 left-0 w-full h-full rounded-lg border border-gray-300"
            title="New Recording - 10. 6. 2021 8:33:31"
        ></iframe>
    </div>
    <script src="https://player.vimeo.com/api/player.js"></script>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button *ngIf="!data.auto" class="btn btn-outline-primary" mat-button mat-dialog-close>
        {{ 'section.dialog.help.close' | translate }}
    </button>
    <button *ngIf="data.auto" class="btn btn-outline-primary" (click)="notShowDialog(data.name)" mat-button mat-dialog-close>OK</button>
    <button *ngIf="data.auto" class="btn btn-outline-primary" mat-button mat-dialog-close>
        {{ 'section.dialog.help.watch_later' | translate }}
    </button>
</mat-dialog-actions>
