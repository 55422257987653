<div class="md:max-w-96">
    <div class="flex flex-row justify-between text-right items-center">
        <img src="assets/images/payment.png" [alt]="'section.branding.payment.method' | translate" class="w-12 h-4" />
        <h1 class="text-2xl mx-auto font-semibold">{{ 'section.branding.payment.method' | translate }}</h1>
        <button mat-icon-button (click)="dialogRef.close(false)">
            <i class="icon-size-4 fas fa-times"></i>
        </button>
    </div>
    <div class="flex items-center justify-center h-50-vh" *ngIf="loading">
        <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
    <ng-container *ngIf="!threeDrequired">
        <div class="text-center" *ngIf="!loading">
            <p class="mt-8" *ngIf="!isCardChecked" [innerHTML]="'section.branding.payment.method_info' | translate"></p>
        </div>

        <div mat-dialog-content class="mat-typography" [class.invisible]="loading">
            <div class="stripe card-container">
                <div #cardElement class="field py-12 items-center" *ngIf="!isCardChecked"></div>
                <div class="card md:w-80 md:h-42" *ngIf="isCardChecked">
                    <div class="grid grid-cols-12">
                        <div class="col-span-12">{{ 'section.branding.payment.card' | translate }}</div>
                    </div>
                    <div class="grid grid-cols-12">
                        <div class="col-span-12 card-number py-4 md:pt-20 md:pb-8 text-lg md:text-2xl">
                            **** **** **** {{ cardInfo?.last4 }}
                        </div>
                    </div>
                    <div class="grid grid-cols-12">
                        <div class="col-span-6"></div>
                        <div class="col-span-6 text-right text-md md:text-lg">{{ cardInfo?.cardExp }}</div>
                    </div>
                </div>

                <div class="text-center" *ngIf="!loading">
                    <button
                        *ngIf="productData && isCardChecked"
                        (click)="productData.type === 'one_time' ? oneTimePayment() : createSubscription()"
                        class="bg-primary hover:bg-secondary ease-in-out duration-500 py-2 px-4 mt-4 text-lg font-normal text-white rounded-md"
                        [disabled]="loading || isSubmitDisabled"
                    >
                        {{ 'section.branding.payment.pay' | translate }}
                    </button>
                    <button
                        *ngIf="productData && !isCardChecked"
                        (click)="checkCard()"
                        class="bg-primary hover:bg-secondary ease-in-out duration-500 py-2 px-4 mt-4 text-lg font-normal text-white rounded-md"
                        [disabled]="loading || isSubmitDisabled"
                    >
                        {{ 'section.branding.payment.check_card' | translate }}
                    </button>
                    <button
                        *ngIf="productData && isCardChecked"
                        (click)="changeCard()"
                        class="bg-primary hover:bg-secondary ease-in-out duration-500 py-2 md:px-4 px-2 md:ml-4 ml-2 mt-4 text-lg font-normal text-white rounded-md"
                        [disabled]="loading || isSubmitDisabled"
                    >
                        {{ 'section.branding.payment.change_card' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
    <iframe [src]="threeDUrl" class="three-d-secure" *ngIf="threeDrequired && !loading"></iframe>
</div>
