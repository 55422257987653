<div class="relative flex justify-center items-start">
    <div class="mx-auto flex flex-col items-center mt-2">
        <h2 class="text-3xl font-medium text-secondary font-sans flex" mat-dialog-title *ngIf="!data.id && !isCopy">
            {{ 'section.calculations.dialog.new_calc_title' | translate }}
        </h2>
        <h2 class="text-3xl font-medium text-secondary font-sans flex" mat-dialog-title *ngIf="data.id || isCopy">
            {{ data.calcName ?? ('section.calculations.dialog.without_title' | translate) }}
        </h2>
        <div class="text-gray-400 text-lg font-normal -mt-3" *ngIf="data?.calculationType">
            {{ 'section.calculations.' + data.calculationType + '.title' | translate }}
        </div>
    </div>
    <button class="absolute top-0 right-0 rounded-md" mat-button [autofocus]="false" [mat-dialog-close]="true">
        <mat-icon class="text-3xl">close</mat-icon>
    </button>
</div>

<mat-dialog-content class="mat-typography -mx-6">
    <form #calculationForm class="kfp-form" [formGroup]="form" (submit)="submit()">
        <div
            [ngClass]="{
                'has-error': form.controls['calcName'].invalid && (form.controls['calcName'].dirty || form.controls['calcName'].touched)
            }"
        >
            <label for="calcName">{{ 'section.calculations.dialog.calc_name' | translate }}</label>
            <div class="form-group">
                <input
                    class="search-input my-2 text-black text-lg w-full h-12 py-2 px-4 bg-white rounded-md border border-gray-300 placeholder-gray-500 placeholder-lg placeholder-font-normal"
                    #calcName
                    id="calcName"
                    type="text"
                    name="calcName"
                    placeholder="{{ 'section.calculations.dialog.calc_name.placeholder' | translate }}"
                    [formControl]="form.controls['calcName']"
                    [(ngModel)]="data.calcName"
                />
            </div>
        </div>

        <div class="grid grid-flow-col justify-stretch">
            <div class="col-md-6 mr-8">
                <label for="clientName">{{ 'section.calculations.dialog.client_name' | translate }}</label>
                <div class="form-group">
                    <input
                        class="search-input my-2 text-black text-lg w-full h-12 py-2 px-4 bg-white rounded-md border border-gray-300 placeholder-gray-500 placeholder-lg placeholder-font-normal"
                        id="clientName"
                        type="text"
                        name="clientName"
                        placeholder="{{ 'section.calculations.dialog.client_name.placeholder' | translate }}"
                        [formControl]="form.controls['clientName']"
                        [(ngModel)]="data.clientName"
                    />
                </div>
            </div>
            <div class="col-md-6">
                <label for="clientSurname">{{ 'section.calculations.dialog.client_surname' | translate }}</label>
                <div class="form-group">
                    <input
                        class="search-input my-2 text-black text-lg w-full h-12 py-2 px-4 bg-white rounded-md border border-gray-300 placeholder-gray-500 placeholder-lg placeholder-font-normal"
                        id="clientSurname"
                        type="text"
                        name="clientSurname"
                        placeholder="{{ 'section.calculations.dialog.client_surname.placeholder' | translate }}"
                        [formControl]="form.controls['clientSurname']"
                        [(ngModel)]="data.clientSurname"
                    />
                </div>
            </div>
        </div>

        <label for="description">{{ 'section.calculations.dialog.description' | translate }}</label>
        <div class="form-group">
            <textarea
                class="search-input my-2 text-black text-lg w-full h-24 py-2 px-4 bg-white rounded-md border border-gray-300 placeholder-gray-500 placeholder-lg placeholder-font-normal"
                [maxlength]="80"
                name="description"
                id="description"
                placeholder="{{ 'section.calculations.dialog.description.placeholder' | translate }}"
                [formControl]="form.controls['description']"
                [(ngModel)]="data.description"
            >
            </textarea>

            <span style="color: red" *ngIf="data.description?.length >= 80">
                {{ 'section.calculations.dialog.max_characters' | translate }}
            </span>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button (click)="submit()" class="bg-secondary h-12 text-lg font-normal text-white rounded-md" mat-button>
        <span *ngIf="!data.id && !isCopy">{{ 'section.calculations.dialog.create' | translate }}</span>
        <span *ngIf="data.id && !isCopy">{{ 'section.calculations.dialog.save' | translate }}</span>
        <span *ngIf="isCopy">{{ 'section.calculations.dialog.duplicate' | translate }}</span>
    </button>
</mat-dialog-actions>
