import {Injectable, OnDestroy} from '@angular/core';
import {CalculationResource, CalculationService, CreateCalculationRequest} from 'kfp';
import {
    BehaviorSubject,
    exhaustMap,
    filter,
    flatMap,
    map,
    Observable,
    of,
    pipe,
    share,
    Subject,
    switchMap,
    take,
    takeUntil
} from 'rxjs';
import {HistoricalParametersService} from '../calculations/historical/domain/services/historical-parameters.service';
import {HistoricalChartService} from '../calculations/historical/domain/services/historical-chart.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ProbabilityParametersService} from '../calculations/probability/domain/services/probability-parameters.service';
import {ProbabilityChartService} from '../calculations/probability/domain/services/probability-chart.service';
import {AppService} from './kfp.service';
import moment from 'moment';
import {FinMathParametersService} from '../calculations/finmath/domain/services/finmath-parameters.service';
import {FinMathChartService} from '../calculations/finmath/domain/services/finmath-chart.service';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../auth/auth.service';
import {EstatesChartService} from '../calculations/estates/domain/services/estates-chart.service';
import {EstatesParametersService} from '../calculations/estates/domain/services/estates-parameters.service';
import {MortgageChartService} from '../calculations/mortgage/services/mortgage-chart.service';
import {MortgageParametersService} from '../calculations/mortgage/services/mortgage-parameters.service';
import {cloneDeep} from 'lodash';
import {ProfileService} from '../profile/profile.service';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class KfpCalculationService implements OnDestroy {
    private dataSubject = new BehaviorSubject<CreateCalculationRequest | null>(null);
    data$ = this.dataSubject.asObservable();
    calculation?: any;
    private observableCalculation: Observable<any> | null | undefined;
    private destroy$ = new Subject<void>();
    private euroCoef = 20;
    private loggedUser?: any;

    sendData(data: CreateCalculationRequest) {
        this.dataSubject.next(data);
    }

    constructor(
        private _calculationService: CalculationService,
        private _historicalParametersService: HistoricalParametersService,
        private _historicalChartService: HistoricalChartService,
        private _probabilityParametersService: ProbabilityParametersService,
        private _probabilityChartService: ProbabilityChartService,
        private _estateChartService: EstatesChartService,
        private _estateParametersService: EstatesParametersService,
        private _finMathParametersService: FinMathParametersService,
        private _finMathChartService: FinMathChartService,
        private _mortgageChartService: MortgageChartService,
        private _mortgageParametersService: MortgageParametersService,
        private _appService: AppService,
        private profileService: ProfileService,
        private router: Router,
        private _toastr: ToastrService,
        private activatedRoute: ActivatedRoute,
        private authService: AuthService
    ) {
        this.router.events
            .pipe(
                filter(e => e instanceof NavigationEnd),
                untilDestroyed(this),
                switchMap(() => {
                    const currNavigation = this.router.getCurrentNavigation();
                    const calcId = currNavigation?.extractedUrl?.queryParams.id;

                    if (calcId && (!this.calculation || calcId !== this.calculation?.id)) {
                        return this._calculationService.getCalculationById(calcId);
                    }
                    return of(null);
                })
            )
            .subscribe((response: CalculationResource | null) => {
                this.calculation = response;
            });

        this.profileService.getAuthUser().subscribe((value) => {
            this.loggedUser = value;
        });

    }

    public createCalculation(createCalculationRequest: CreateCalculationRequest): Observable<CalculationResource> {
        return this._calculationService.createNewCalculation(createCalculationRequest);
    }

    public detailCalculation(id: string): Observable<CalculationResource> {
        return this._calculationService.getCalculationById(id);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public downloadPdf(id: string, credit: boolean, investment: boolean, rent: boolean, selectedTabs?: number[]): Observable<void> {

        let stringTabs: string;
        if (selectedTabs) {
            stringTabs = selectedTabs.join(',');
        }
        return new Observable<void>((observer) => {
            this._calculationService.createPdfForCalculation(id, credit, investment, rent, stringTabs).subscribe(
                (data: any) => {
                    const filename = data['name'];
                    if (this._appService.getData()?.calculationType === 'fin_math') {
                        const trueBooleansCount = [credit, investment, rent].filter(Boolean).length;

                        const blobType = trueBooleansCount === 1 ? 'application/pdf' : 'application/zip';
                        const fileExtension = trueBooleansCount === 1 ? 'pdf' : 'zip';
                        const blob = new Blob([data], {type: blobType});
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        if (filename) {
                            link.download = filename.replace(/\.[^/.]+$/, "") + `.${fileExtension}`;
                        } else {
                            link.download = moment().format('DD_MM_YYYY').toString() + `-calculations.${fileExtension}`;
                        }
                        link.click();
                    } else if (this._appService.getData()?.calculationType === 'mortgage') {
                        const blob = new Blob([data], {type: 'application/zip'});
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        if (filename) {
                            link.download = filename;
                        } else {
                            link.download = moment().format('DD_MM_YYYY').toString() + '-calculations.zip';
                        }
                        link.click();
                    } else {
                        const blob = new Blob([data], {type: 'application/pdf'});
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        if (filename) {
                            link.download = filename;
                        } else {
                            link.download = moment().format('DD_MM_YYYY').toString() + '-calculation.pdf';
                        }
                        link.click();
                    }
                    observer.next(); // Notify completion
                    observer.complete();
                },
                (error) => {
                    this._toastr.error(error?.error?.message, 'Nastala chyba');
                    observer.error(error); // Propagate error
                },
                () => {
                    observer.complete(); // Ensure completion in case of early termination
                }
            );
        });
    }

    public prepareData(): Observable<CreateCalculationRequest> | undefined {
        const calculationData = this._appService.getData();

        if (calculationData?.calculationType === 'history') {
            return this.prepareDataHistory();
        }
        if (calculationData?.calculationType === 'probability') {
            return this.prepareDataProbability();
        }
        if (calculationData?.calculationType === 'fin_math') {
            return this.prepareDataFinMath();
        }
        if (calculationData?.calculationType === 'profit') {
            return this.prepareDataEstate();
        }
        if (calculationData?.calculationType === 'mortgage') {
            return this.prepareDataMortgage();
        }
        return undefined;
    }

    public prepareDataHistory(): Observable<CreateCalculationRequest> {
        return this._historicalParametersService.sharedParams$.pipe(
            untilDestroyed(this),
            switchMap(params => this._historicalChartService.prepareDataForPdfExport(params)),
            switchMap(chartData => of({chart: chartData, params: {}})),
            switchMap((data) => {
                data.params = this._historicalParametersService.getParameters();
                return of(data);
            }),
            switchMap(data =>
                of(
                    new Object({
                        calculationType: 'history',
                        clientName: this.loggedUser.firstname,
                        clientSurname: this.loggedUser.lastname,
                        currency: this.authService.currency,
                        description: '',
                        draft: true,
                        created: '',
                        updated: '',
                        inputData: {
                            // @ts-ignore
                            breakPresets: {},
                            currency: this.authService.currency,
                            mode: this._historicalParametersService.brakeMode,
                            modeRatios: {},
                            parameters: data.params,
                            charts: data.chart,
                            lang: this.authService.language
                        },
                    }) as CreateCalculationRequest
                )
            )
        );
    }

    public prepareDataProbability(): Observable<CreateCalculationRequest> {
        return this._probabilityChartService.prepareDataForPdfExport(this._probabilityParametersService.getParameters()).pipe(
            switchMap((chartData: any) => {
                const parameters = this._probabilityParametersService.getParameters();
                const presentData = {
                    parameters,
                    chart: chartData,
                };

                return of(
                    new Object({
                        calculationType: 'probability',
                        clientName: this.loggedUser.firstname,
                        clientSurname: this.loggedUser.lastname,
                        currency: this.authService.currency,
                        description: '',
                        draft: true,
                        username: 'Test',
                        created: '',
                        updated: '',
                        inputData: {
                            // @ts-ignore
                            breakPresets: {},
                            currency: this.authService.currency,
                            mode: 'custom',
                            modeRatios: {},
                            parameters: presentData.parameters,
                            charts: presentData.chart,
                            lang: this.authService.language,
                            horizons: this._probabilityParametersService.selectedHorizons
                        },
                    }) as CreateCalculationRequest
                );
            }),
            share()
        );
    }

    public prepareDataFinMath(): Observable<CreateCalculationRequest> {
        return this._finMathChartService.prepareDataForPdfExport(this._finMathParametersService.getAllParametersFromPreset()).pipe(
            switchMap((chartParams: any) => {
                const presets = this._finMathParametersService.getPreset();
                const parameters = this._finMathParametersService.getParameters();
                const mode = this._finMathParametersService.mode;
                const presentData = {
                    presets,
                    parameters,
                    mode,
                    chart: chartParams,
                };

                return of(
                    new Object({
                        calculationType: 'fin_math',
                        clientName: this.loggedUser.firstname,
                        clientSurname: this.loggedUser.lastname,
                        currency: this.authService.currency,
                        description: '',
                        draft: true,
                        username: 'Test',
                        created: '',
                        updated: '',
                        inputData: {
                            // @ts-ignore
                            flagField: '',
                            currency: this.authService.currency,
                            mode: presentData.mode,
                            parameters: presentData.parameters,
                            presets: presentData.presets,
                            charts: presentData.chart,
                            lang: this.authService.language
                        },
                    }) as CreateCalculationRequest
                );
            }),
            share()
        );
    }

    public prepareDataEstate(): Observable<CreateCalculationRequest> {
        return this._estateChartService.prepareDataForPdfExport(this._estateParametersService.getParameters()).pipe(
            switchMap((chartData: any) => {
                const parameters = this._estateParametersService.getParameters();
                const presentData = {
                    parameters,
                    chart: chartData,
                };

                return of(
                    new Object({
                        calculationType: 'profit',
                        clientName: this.loggedUser.firstname,
                        clientSurname: this.loggedUser.lastname,
                        currency: this.authService.currency,
                        description: '',
                        draft: true,
                        username: 'Test',
                        created: '',
                        updated: '',
                        inputData: {
                            // @ts-ignore
                            currency: this.authService.currency,
                            mode: 'custom',
                            modeRatios: {},
                            parameters: presentData.parameters,
                            charts: presentData.chart,
                            lang: this.authService.language
                        },
                    }) as CreateCalculationRequest
                );
            }),
            share()
        );
    }

    public prepareDataMortgage(): Observable<CreateCalculationRequest> {
        return this._mortgageChartService.prepareDataForPdfExport(this._mortgageParametersService.getParameters()).pipe(
            switchMap((chartData: any) => {
                const parameters = this._mortgageParametersService.getParameters();
                const compareTabs = this._mortgageParametersService.getComparingTabs();
                const selectedTab = this._mortgageParametersService.getSelectedTab();

                const presentData = {
                    parameters,
                    chart: chartData,
                };

                return of(
                    new Object({
                        calculationType: 'mortgage',
                        clientName: this.loggedUser.firstname,
                        clientSurname: this.loggedUser.lastname,
                        currency: this.authService.currency,
                        description: '',
                        draft: true,
                        username: 'Test',
                        created: '',
                        updated: '',
                        inputData: {
                            // @ts-ignore
                            currency: this.authService.currency,
                            mode: 'custom',
                            modeRatios: {},
                            parameters: presentData.parameters,
                            charts: presentData.chart,
                            lang: this.authService.language,
                            compareTabs: compareTabs,
                            selectedTab: selectedTab,
                        },
                    }) as CreateCalculationRequest
                );
            }),
            share()
        );
    }

    public activeCalculation(): Observable<any> {
        if (this.calculation) {
            return of(this.calculation);
        } else if (this.observableCalculation) {
            return this.observableCalculation;
        } else {
            const id = this.activatedRoute.snapshot.queryParams['id'];
            if (!id) {
                return of(null);
            }

            // Fetch calculation data only if id is valid
            this.observableCalculation = this._calculationService.getCalculationById(id).pipe(
                map((data: any) => {
                    this.calculation = data;
                    this.observableCalculation = null;

                    const sharedData = {
                        calculationType: this.calculation.calculationType,
                        title: this.calculation.calcName,
                        concept: false,
                        storable: true,
                        download: true,
                        new: false,
                        data: this.calculation.inputData,
                        charts: this.calculation.charts,
                        lang: this.authService.language
                    };
                    this._appService.setData(sharedData);

                    return this.calculation;
                })
            );

            return this.observableCalculation;
        }
    }

    public updateFinMathCalculation(): Observable<any> | null {
        const calcId = this.calculation?.id;
        if (!calcId) {
            return null;
        }
        return this._finMathChartService.prepareDataForPdfExport(this._finMathParametersService.getAllParametersFromPreset()).pipe(
            take(1),
            exhaustMap(charts =>
                this._calculationService.updateCalculation(calcId, {
                    calcName: this.calculation?.calcName,
                    calculationType: 'fin_math',
                    clientName: this.calculation?.clientName,
                    clientSurname: this.calculation?.clientSurname,
                    currency: this.authService.currency,
                    description: this.calculation?.description,
                    draft: false,
                    username: this.calculation?.username,
                    created: '',
                    updated: '',
                    inputData: {
                        // @ts-ignore
                        currency: this.authService.currency,
                        mode: this._finMathParametersService.mode,
                        parameters: this._finMathParametersService.getParameters(),
                        presets: this._finMathParametersService.getPreset(),
                        charts: charts,
                        lang: this.authService.language
                    },
                })
            ),
            takeUntil(this.destroy$), // Unsubscribe when the component is destroyed
            map((response: any) => {
                this.calculation = response;
                return response;
            })
        );
    }

    public updateProbabilityCalculation(): any {
        const chartData = this._probabilityChartService.prepareDataForPdfExport(this._probabilityParametersService.getParameters());
        const parameters = this._probabilityParametersService.getParameters();
        if (this.calculation?.id) {
            return chartData.pipe(
                take(1),
                exhaustMap(charts =>
                    this._calculationService.updateCalculation(this.calculation.id, {
                        calcName: this.calculation.calcName,
                        calculationType: 'probability',
                        clientName: this.calculation.clientName,
                        clientSurname: this.calculation.clientSurname,
                        currency: this.authService.currency,
                        description: this.calculation.description,
                        draft: false,
                        username: this.calculation.username,
                        created: '',
                        updated: '',
                        inputData: {
                            // @ts-ignore
                            currency: this.authService.currency,
                            mode: 'custom',
                            modeRatios: {},
                            parameters,
                            charts,
                            lang: this.authService.language,
                            horizons: this._probabilityParametersService.selectedHorizons
                        },
                    })
                ),
                takeUntil(this.destroy$), // Unsubscribe when the component is destroyed
                map((response: any) => {
                    this.calculation = response;
                    return response;
                })
            );
        }
    }

    public updateHistoryCalculation(): any {
        const chartData = this._historicalChartService.prepareDataForPdfExport(this._historicalParametersService.getParameters());
        const parameters = this._historicalParametersService.getParameters();

        if (this.calculation?.id) {
            return chartData.pipe(
                take(1),
                exhaustMap(charts =>
                    this._calculationService.updateCalculation(this.calculation?.id, {
                        calcName: this.calculation?.calcName,
                        calculationType: 'history',
                        clientName: this.calculation?.clientName,
                        clientSurname: this.calculation?.clientSurname,
                        currency: this.authService.currency,
                        description: this.calculation?.description,
                        draft: false,
                        username: this.calculation?.username,
                        created: '',
                        updated: '',
                        inputData: {
                            // @ts-ignore
                            currency: this.authService.currency,
                            mode: this._historicalParametersService.brakeMode,
                            modeRatios: {},
                            parameters,
                            charts,
                            lang: this.authService.language
                        },
                    })
                ),
                takeUntil(this.destroy$), // Unsubscribe when the component is destroyed
                map((response: any) => {
                    this.calculation = response;
                    return response;
                })
            );
        }
    }

    public updateEstateCalculation(): any {
        const chartData = this._estateChartService.prepareDataForPdfExport(this._estateParametersService.getParameters());
        const parameters = this._estateParametersService.getParameters();
        if (this.calculation?.id) {
            return chartData.pipe(
                take(1),
                exhaustMap(charts =>
                    this._calculationService.updateCalculation(this.calculation.id, {
                        calcName: this.calculation.calcName,
                        calculationType: 'profit',
                        clientName: this.calculation.clientName,
                        clientSurname: this.calculation.clientSurname,
                        currency: this.authService.currency,
                        description: this.calculation.description,
                        draft: false,
                        username: this.calculation.username,
                        created: '',
                        updated: '',
                        inputData: {
                            // @ts-ignore
                            currency: this.authService.currency,
                            mode: 'custom',
                            modeRatios: {},
                            parameters,
                            charts,
                            lang: this.authService.language
                        },
                    })
                ),
                takeUntil(this.destroy$), // Unsubscribe when the component is destroyed
                map((response: any) => {
                    this.calculation = response;
                    return response;
                })
            );
        }
    }

    public calculationCurrency(calculationType: string | undefined) {
        if (this.authService.currency === '€') {
            if (calculationType === 'fin_math') {
                const preset = this._finMathParametersService.getPreset();

                preset.credit.initialPayment = Math.round(preset.credit.initialPayment / this.euroCoef);
                preset.credit.monthlyPayment = Math.round(preset.credit.monthlyPayment / this.euroCoef);
                preset.investment.initialPayment = Math.round(preset.investment.initialPayment / this.euroCoef);
                preset.investment.monthlyPayment = Math.round(preset.investment.monthlyPayment / this.euroCoef);
                preset.rent.initialPayment = Math.round(preset.rent.initialPayment / this.euroCoef);
                preset.rent.monthlyPayment = Math.round(preset.rent.monthlyPayment / this.euroCoef);
                this._finMathParametersService.setPreset(preset);
            }
            if (calculationType === 'probability') {
                const preset = this._probabilityParametersService.getParameters();

                preset.initialInvestment = Math.round(preset.initialInvestment / this.euroCoef);
                preset.monthlyInvestment = Math.round(preset.monthlyInvestment / this.euroCoef);
                this._probabilityParametersService.setParameters(preset);
            }
            if (calculationType === 'history') {
                const preset = this._historicalParametersService.getParameters();

                preset.initialInvestment = Math.round(preset.initialInvestment / this.euroCoef);
                preset.monthlyInvestment = Math.round(preset.monthlyInvestment / this.euroCoef);
                this._historicalParametersService.setParameters(preset);
            }
            if (calculationType === 'profit') {
                const preset = this._estateParametersService.getParameters() as any;

                preset.mortgage.ownResource = Math.round(preset.mortgage.ownResource / this.euroCoef);
                preset.mortgage.loanValue = Math.round(preset.mortgage?.loanValue / this.euroCoef);
                preset.mortgage.monthlyPayFlagged = Math.round(preset.mortgage?.monthlyPayFlagged / this.euroCoef);

                preset.realEstate.marketPrice = Math.round(preset.realEstate.marketPrice / this.euroCoef);
                preset.realEstate.purchasePrice = Math.round(preset.realEstate.purchasePrice / this.euroCoef);
                preset.rentalIncome.monthlyRent = Math.round(preset.rentalIncome.monthlyRent / this.euroCoef);

                this._estateParametersService.setParameters(preset);
            }
            if (calculationType === 'mortgage') {
                const preset = this._mortgageParametersService.getParameters();

                preset.items[0].data.mortgage.initialPayment = preset.items[0].data.mortgage.initialPayment / this.euroCoef;
                preset.items[0].data.mortgage.monthlyPaymentFlaged = Math.round(preset.items[0].data.mortgage.monthlyPaymentFlaged / this.euroCoef);
                preset.items[0].data.investment.futureValue = preset.items[0].data.investment.futureValue / this.euroCoef;
                preset.items[0].data.investment.initialPayment = preset.items[0].data.investment.initialPayment / this.euroCoef;
                preset.items[0].data.investment.monthlyPayment = Math.round(preset.items[0].data.investment.monthlyPayment / this.euroCoef);

                preset.items[1].data.mortgage.initialPayment = preset.items[1].data.mortgage.initialPayment / this.euroCoef;
                preset.items[1].data.mortgage.monthlyPaymentFlaged = Math.round(preset.items[1].data.mortgage.monthlyPaymentFlaged / this.euroCoef);
                preset.items[1].data.investment.futureValueFlaged = preset.items[1].data.investment.futureValueFlaged / this.euroCoef;
                preset.items[1].data.investment.initialPayment = preset.items[1].data.investment.initialPayment / this.euroCoef;
                preset.items[1].data.investment.monthlyPayment = Math.round(preset.items[1].data.investment.monthlyPayment / this.euroCoef);

                this._mortgageParametersService.setParameters(preset);
            }
        }
    }

    public updateMortgageCalculation(): any {
        const chartData = this._mortgageChartService.prepareDataForPdfExport(this._mortgageParametersService.getParameters());
        const parameters = this._mortgageParametersService.getParameters();
        const compareTabs = this._mortgageParametersService.getComparingTabs();
        const selectedTab = this._mortgageParametersService.getSelectedTab();
        if (this.calculation?.id) {
            return chartData.pipe(
                take(1),
                exhaustMap(charts =>
                    this._calculationService.updateCalculation(this.calculation.id, {
                        calcName: this.calculation.calcName,
                        calculationType: 'mortgage',
                        clientName: this.calculation.clientName,
                        clientSurname: this.calculation.clientSurname,
                        currency: this.authService.currency,
                        description: this.calculation.description,
                        draft: false,
                        username: this.calculation.username,
                        created: '',
                        updated: '',
                        inputData: {
                            // @ts-ignore
                            currency: this.authService.currency,
                            mode: 'custom',
                            modeRatios: {},
                            parameters,
                            charts,
                            compareTabs: compareTabs,
                            selectedTab: selectedTab,
                            lang: this.authService.language
                        },
                    })
                ),
                takeUntil(this.destroy$), // Unsubscribe when the component is destroyed
                map((response: any) => {
                    this.calculation = response;
                    return response;
                })
            );
        }
    }


}
