import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntilDestroy} from '@ngneat/until-destroy';

import {MortgageParametersService} from '../services/mortgage-parameters.service';


@UntilDestroy()
@Component({
    selector: 'mortgage-pdf-dialog',
    templateUrl: './mortgage-pdf-dialog.component.html',
    styleUrls: ['./mortgage-pdf-dialog.component.scss'],
})
export class MortgagePdfDialogComponent implements OnInit {
    public calculations: any = {};
    public resp?: boolean[];

    constructor(
        @Inject(MAT_DIALOG_DATA) public dataRef: any,
        private dialogRef: MatDialogRef<any>,
        private readonly _mortParamServ: MortgageParametersService,
    ) {
    }

    ngOnInit(): void {
        this.calculations = this._mortParamServ.getParameters();
        if (this.calculations && this.calculations.items) {
            this.resp = [];

            this.calculations.items.forEach(() => {
                this.resp?.push(true);
            })
        }

        this.dialogRef.updateSize('544px', '560px');
    }

    public submit(): boolean {

        const trueIndexes = this.resp?.reduce((acc, val, index) => val ? [...acc, index] : acc, [] as number[]);
        this.dialogRef.close(trueIndexes);

        return false;
    }

    handleCardClick(index: number) {
        if (this.resp) {
            this.resp[index] = !this.resp[index];
        }
    }
}
