<div class="flex justify-between items-start">
    <div class="mx-auto flex flex-col items-center mt-2">
        <h2 class="text-3xl font-medium text-secondary font-sans flex" mat-dialog-title>
            {{ 'mortgage.calculation.select_tab' | translate }}
        </h2>

    </div>
    <div class="flex flex-col items-end">
        <button class="rounded-md" mat-button [autofocus]="false" [mat-dialog-close]="true">
            <mat-icon class="text-3xl">close</mat-icon>
        </button>
    </div>
</div>

<mat-dialog-content class="mat-typography -mx-6">
    <div class="kfp-form">

        <div class="flex flex-wrap justify-center">
            <div
                class="max-w-sm rounded-lg overflow-hidden border border-gray-300 mx-2 my-2 cursor-pointer transform hover:scale-110">
                <div class="px-6 py-4" (click)="this.credit = !this.credit; $event.stopPropagation()">
                    <mat-checkbox class="example-margin" [ngModel]="this.credit">{{"fin_math.calculation.tab.parameters.loan" | translate}}</mat-checkbox>
                </div>
            </div>
            <div
                class="max-w-sm rounded-lg overflow-hidden border border-gray-300 mx-2 my-2 cursor-pointer transform hover:scale-110">
                <div class="px-6 py-4" (click)="this.investment = !this.investment; $event.stopPropagation()">
                    <mat-checkbox class="example-margin" [ngModel]="this.investment">{{"fin_math.calculation.tab.parameters.investment" | translate}}</mat-checkbox>
                </div>
            </div>
            <div
                class="max-w-sm rounded-lg overflow-hidden border border-gray-300 mx-2 my-2 cursor-pointer transform hover:scale-110">
                <div class="px-6 py-4" (click)="this.rent = !this.rent; $event.stopPropagation()">
                    <mat-checkbox class="example-margin" [ngModel]="this.rent">{{"fin_math.calculation.tab.parameters.rent" | translate}}</mat-checkbox>
                </div>
            </div>
        </div>

    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button (click)="submit()" class="bg-secondary h-12 text-lg font-normal text-white rounded-md" mat-button>
        <span>{{ 'mortgage.calculation.set_selected' | translate }}</span>
    </button>
</mat-dialog-actions>
