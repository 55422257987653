import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import * as Sentry from '@sentry/angular-ivy';
import {environment} from './environments/environment';

Sentry.init({
    dsn: "https://bccbbd886221995dcd601ad611206ab8@o1210468.ingest.us.sentry.io/4507254153347072",
    integrations: [
        // Registers and configures the Tracing integration,
        // which automatically instruments your application to monitor its
        // performance, including custom Angular routing instrumentation
        Sentry.browserTracingIntegration(),
        // Registers the Replay integration,
        // which automatically captures Session Replays
        Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: environment.production ? 'production' : 'development',
    enabled: environment.production,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [ /^https:\/\/dev-kfp\.techcrowd\.space\.cz/, /^https:\/\/app\.kalkulackykfp\.cz/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

platformBrowserDynamic().bootstrapModule(AppModule)
                        .catch(err => console.error(err));
