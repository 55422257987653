import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {AvailableLangs, TranslocoService} from '@ngneat/transloco';
import {FuseNavigationService} from 'fuseui';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'languages',
    templateUrl: './languages.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'languages'
})
export class LanguagesComponent implements OnInit {
    availableLangs!: AvailableLangs;
    activeLang!: string;
    flagCodes: any;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _translocoService: TranslocoService,
        private _translateService: TranslateService) {
    }

    ngOnInit(): void {

        this.availableLangs = this._translocoService.getAvailableLangs();

        this.activeLang = this._translateService.currentLang;

        this.flagCodes = {
            cs: 'CS',
            en: 'EN',
            de: 'DE'
        };
    }

    setActiveLang(lang: string): void {
        this._translateService.use(lang);
        this.activeLang = lang;
        location.reload();
    }

    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

}
