import { formatNumber } from '@angular/common';
import { ApexAxisChartSeries } from 'ng-apexcharts';
import { Injectable, Input } from '@angular/core';
import { BehaviorSubject, of, map, combineLatestWith } from 'rxjs';
import { ChartOptions } from '../services/historical-chart.service';
import { AuthService } from '../../../../auth/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class EvaluationChart {
    constructor(private authService: AuthService, private translateService: TranslateService) {
        this.updateChartHeight();
        this.translateService
            .stream([
                'historical.calculation.chart.stock',
                'historical.calculation.chart.bonds',
                'historical.calculation.chart.money_market',
                'historical.calculation.chart.inflation',
            ])
            .subscribe((keys) => {

                this.dataChart.next({
                    series: {} as ApexAxisChartSeries,
                    chart: {
                        background:
                            'linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(/' + this.logo + ') no-repeat center; background-size: auto 30%;',
                        toolbar: {
                            tools: { download: false },
                        },
                        type: 'bar',
                        height: this.height,
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 10,
                            barHeight: '60%',
                            distributed: true,
                            horizontal: true,
                            dataLabels: {
                                position: 'top',
                            },
                        },
                    },
                    colors: ['#E84E0E', '#002B4F', '#FBBC05', '#7850EB'],
                    xaxis: {
                        min: 1,
                        categories: [
                            keys['historical.calculation.chart.stock'],
                            keys['historical.calculation.chart.bonds'],
                            keys['historical.calculation.chart.money_market'],
                            keys['historical.calculation.chart.inflation'],
                        ],
                        labels: {
                            style: {
                                colors: '#95959a',
                                fontSize: '12px',
                            },
                            formatter: (value: any) => formatNumber(value, 'cs', '1.0-0') + '%',
                        },
                    },
                    yaxis: {
                        forceNiceScale: true,
                        labels: {
                            style: {
                                colors: '#95959a',
                                fontSize: '12px',
                            },
                        },
                    },
                    dataLabels: {
                        formatter: (value: any) => formatNumber(value, 'cs', '1.2-2') + '%',
                        offsetX: 120,
                        style: {
                            fontSize: '16px',
                            colors: ['#002B4F'],
                        },
                    },
                    legend: {
                        show: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                });
            });
    }

    public chart!: ChartOptions;
    logo = this.authService.getLogoPath();
    @Input() height?: number;

    private dataChart = new BehaviorSubject<ChartOptions | null>(null);

    data$ = this.dataChart.asObservable();

    updateSeries(newValue: ApexAxisChartSeries): void {
        const currentObject = this.dataChart.value;
        this.updateChartHeight();
        if (currentObject) {
            const updatedObject = { ...currentObject };
            updatedObject.series = newValue;
            updatedObject.chart.height = this.height;
            this.logo = this.authService.getLogoPath();
            this.dataChart.next(updatedObject);
        }
    }

    getChartData() {
        return this.dataChart;
    }

    updateChartHeight(): void {
        if (window.innerWidth > 1425) {
            this.height = (window.innerHeight * 70) / 100;
        } else if (window.innerWidth > 995) {
            this.height = window.innerHeight - 380;
        } else {
            this.height = window.innerHeight - 480;
        }
    }
}
